<template>
  <div id="amusement">
      <div style="height:66px;"></div>
    <div>
        <div class="wid">
            <img src="../assets/image/amusement.1.png" alt="">
        </div>
        <div>
            白洋淀水上游乐园又称为“宝岛游乐园”，位于国家5A级白洋淀旅游景区内，白洋淀鸳鸯岛景点北侧。白洋淀水上乐园占地85800平方米。
奇特的地理位置、独特构思的人文景观与自然风光巧妙结合，具有浓郁的意趣风情，为来白洋淀旅游的游客观光、休闲提供了绝佳的场所。 白洋淀宝岛水上乐园是集娱乐、荷花观赏为一体，具有极强的参与性、挑战性，融竞技、趣味、刺激、惊险于娱乐之中，其乐无穷。
        </div>
    </div>
    <div>
        <div class="wid">
            <img src="../assets/image/amusement.2.png" alt="">
        </div>
        <div>
            洋淀水上游乐园东方特技铁壁飞车团，深受广大观众的喜爱和欢迎。以精湛的驾驶技术，在大型透明铁壁上表演各种精彩节目，使人眼花缭乱美不胜收。该节目新颖、惊险刺激，特技人才辈出，演员阵容强大，拥有全国首创的两台汽车真人表演更是惊心动魄，非常之惊险。摩托车一字编队场面壮观，使人终生难忘。汽车与摩托车交叉比赛令人惊心愉悦，一场前所未见的高难度、高水准的惊险飞车表演，让您惊心动魄，拍手叫绝。
        </div>
    </div>
  </div>
</template>
<style scoped>
@import '../assets/css/amusement.css'
</style>

<script>
  export default {
    methods: {
      goBack() {
          this.$router.push('/')
        // console.log('go back');
      }
    }
  }
</script>