import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import scroll from 'vue-seamless-scroll'
import axios from 'axios'
import VueAxios from 'vue-axios'
import 'video.js/dist/video-js.css'


import Vant from 'vant';
import 'vant/lib/index.css';

Vue.use(Vant);
//富文本

import VueQuillEditor from 'vue-quill-editor'

import 'quill/dist/quill.core.css' // import styles
import 'quill/dist/quill.snow.css' // for snow theme
import 'quill/dist/quill.bubble.css' // for bubble theme

Vue.use(VueQuillEditor, /* { default global options } */)


import '@wangeditor/editor/dist/css/style.css'


// vue.component('navigation')

Vue.use(VueAxios, axios)
Vue.use(ElementUI);

Vue.use(scroll)
Vue.config.productionTip = false
axios.defaults.baseURL='https://bydfjq.com';
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
