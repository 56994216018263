<template>
  <div id="affectionate">
    <div style="height:66px;"></div>
    <div class="wid">
        <img src="../assets/image/play.3.png" alt="">
        <div>
            鸳鸯岛为白洋淀景区著名的爱情岛，它的周围芦苇环抱，荷花簇拥，岛上设施齐全，集游、购、娱、吃、住、行于一身，有月老祠、天下第一锁等景观，更有白洋淀之窗，里边以详实的图片、文字记录展示了白洋淀的历史变迁及白洋淀水乡风俗。白天，淀风习习，波光粼粼，视野开阔，可以看到淀区秀丽的景色。夜晚、渔家灯火、鸳鸯印月，如诗如画。
        </div>
    </div>
  </div>
</template>
<style scoped>
@import '../assets/css/affectionate.css'
</style>

<script>
  export default {
    methods: {
      goBack() {
          this.$router.push('/')
        // console.log('go back');
      }
    }
  }
</script>