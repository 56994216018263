<template>
<div>
<div class="message">
  <el-table :data="tableData" style="width: 100%">
    <el-table-column
      label="日期"
      width="200">
      <template slot-scope="scope">
        <i class="el-icon-time"></i>
        <span style="margin-left: 10px">{{ scope.row.proposalCreateTime }}</span>
      </template>
    </el-table-column>
    <el-table-column
      label="评论人"
      width="100">
      <template slot-scope="scope">
        <el-popover trigger="hover" placement="top">
            <p>评论人: {{ scope.row.proposalUser }}</p>
          <div slot="reference" class="name-wrapper">
            <el-tag size="medium">{{ scope.row.proposalUser }}</el-tag>
          </div>
        </el-popover>
      </template>
    </el-table-column>
    <el-table-column
      label="评论内容"
      width="1000">
      <template slot-scope="scope">
        <el-popover trigger="hover" placement="top">
            <p>内容: {{ scope.row.proposalContent }}</p>
          <div slot="reference" class="name-wrapper">
            <el-tag size="medium">{{ scope.row.proposalContent }}</el-tag>
          </div>
        </el-popover>
      </template>
    </el-table-column>
    <el-table-column
      label="经办人"
      width="100">
      <template slot-scope="scope">
        <el-popover trigger="hover" placement="top">
          <p>经办人: {{ scope.row.proposalAdmin }}</p>
          <div slot="reference" class="name-wrapper">
            <el-tag size="medium">{{ scope.row.proposalAdmin}}</el-tag>
          </div>
        </el-popover>
      </template>
    </el-table-column>
    <el-table-column label="操作" width="200">
      <template slot-scope="scope">
        <el-button
          size="mini"
          @click="handleEdit(scope.$index, scope.row)">{{scope.row.proposalStage==2 ? '已审核' : '等待审核'}}</el-button>
        <el-button
          size="mini"
          type="danger"
          @click="handleDelete(scope.$index, scope.row)">删除</el-button>
      </template>
    </el-table-column>
  </el-table>
  </div>
  <!-- 分页 -->
    <!-- <el-pagination background layout="prev, pager, next" :total="100" style="margin-left:70%;">
    </el-pagination> -->

    
  </div>
</template>

<style scoped>
    .message{
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
    }
</style>>
    
</style>

<script>
  export default {
    data() {
      return {
        // page:{},//分页
        tableData: [],
      }
    },
    methods: {
        message(){
            this.axios.get( "/proposal/queryProposalByAllList?pageNum=1&pageSize=1000 " ).then((res)=>{
                // console.log(res)
                //   this.page=res.data.data
                this.tableData=res.data.data.list;
                // console.log(this.tableData)
            })
        },
        handleEdit(res,$index){
            if($index.proposalStage==0){
                let a=confirm(`是否通过审核${$index.proposalUser}这条评`)
                if(a==true){
                    this.axios.put("/proposal/updateProposal",{"proposalId": $index.proposalId,"proposalStage":2})
                    .then(res => {
                    // console.log(res)
                    if(res.data.code==200){
                        alert('已通过审核')
                        this.$router.go(0)
                    }
                })
                }else{
                    alert('未通过审核')
                }
                
            }else{
                alert('已通过审核')
            }
        },
        handleDelete($index,res){
            // console.log(res)
            let a=confirm(`是否删除${res.proposalUser}这条评`)
            if(a==true){
                 this.axios.delete(`/proposal/deleteProposal?id=${res.proposalId}`)
                .then(res => {
                // console.log(res)
                if(res.data.code==200){
                    alert('删除成功')
                }
            })
            }else{
                alert('取消删除')
            }
           
           
        }

    },
    mounted(){
        this.message()
    }
  }
</script>