<template>
  <el-carousel :interval="4000" id="el-carousel" style="width:100vw;height:46.875vw">
    <el-carousel-item v-for="(item, index) in dataArr1" :key="index" style="height:100%">
      <img :src="item.imagePath"/>
    </el-carousel-item>
  </el-carousel>
</template>

<script>
export default {
    props:['dataArr1'],
  data() {
    return {
    //   dataArr1: [],
    //   bannerHeight: 900,
    //   screenWidth: 1920,
    };
  },

  mounted() {
    // console.log(this.dataList);
    // this.somliamge();
    // this.setSize1();
    // const that = this;
    //监听浏览器窗口大小改变
    // window.addEventListener(
    //   "resize",
    //   function() {
    //     var width =
    //       window.innerWidth ||
    //       document.documentElement.clientWidth ||
    //       document.body.clientWidth;
    //     that.screenWidth = width;
    //     that.setSize();
    //   },
    //   false
    // );
  },
  methods: {
    // setSize1: function() {
    //   var width =
    //     window.innerWidth ||
    //     document.documentElement.clientWidth ||
    //     document.body.clientWidth;

    //   this.screenWidth = width;
    //   //图片                高 / 宽  700 / 1920
    //   // console.log((900 / 1920) * this.screenWidth < 900);
    //   this.bannerHeight =
    //     (900 / 1920) * this.screenWidth > 900
    //       ? 900
    //       : (900 / 1920) * this.screenWidth;
    //   // console.log(this.bannerHeight);
    //   document.getElementById("el-carousel").style.height =
    //     this.bannerHeight + "px";
    // },

    // setSize: function() {
    //   this.bannerHeight =
    //     (900 / 1920) * this.screenWidth > 900
    //       ? 900
    //       : (900 / 1920) * this.screenWidth;
    //   document.getElementById("el-carousel").style.height =
    //     this.bannerHeight + "px";
    // },
  },
};
</script>

<style>
.el-carousel__container {
  height: 100% !important;
}
/* .el-carousel--horizontal {
  overflow-x: none !important;
} */
img {
  height: 100%;
  width: 100%;
}
</style>
