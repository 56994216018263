<template>
  <div id="app">
    <Header v-if="!$route.meta.showNav" :typeList="typeList"></Header>
    <HeaderH5 v-if="!$route.meta.showNav" :typeList="typeList"></HeaderH5>
    <router-view :typeList="typeList"/>
    <Footer v-if="!$route.meta.showNav"></Footer>
  </div>
</template>



<script>
  import navigation from './components/Navigation';
  import Footer from './components/Footer';
  import Header from './components/Header';
  import HeaderH5 from './components/HeaderH5';
  import { Message } from 'element-ui';


  export default {
    components:{
      navigation,
      Footer,
      Header,
      HeaderH5
    },
    data(){
        return{
            typeList:[]
        }
    },
    computed:{
        // showFooter(){
        //     return this.$store.state.showFooter
        // },
    },
    
    created(){
        this.$store.commit("SET_IMG_URL",'https://bydfjq.com')      //window.location.origin



        // axios拦截器
        this.axios.interceptors.request.use(
            config => {
                let val = localStorage.getItem("BYD_TOKEN_VALUE")
                if(val !== null && val !== '' && val !== undefined){
                    this.axios.defaults.headers.common['token'] = val
                }
                return config
            },
            error => {
            Promise.reject(error)
            }
        )
        // response 拦截器
        this.axios.interceptors.response.use(
            
            response => {
                console.log(1122,response)
                var res = response.data
                if (res.code === 200) {
                    return response
                }  else if (res.code >= 500 && res.code < 600) {
                    let str = res.msg ? res.msg : '请求失败，服务器异常'
                    Message({
                        message: str,
                        type: 'error'
                    });
                }else if(res.code >= 600){
                    // this.$router.push("/")
                    let str = res.data ? res.data : '请求失败，服务器异常'
                    this.$nextTick(()=>{
                        Message({
                            message: str,
                            type: 'error'
                        });
                    })
                }
            },
            error => {
            // 兼容blob下载出错json提示
            if(error.response === undefined){
                if(error.toString().indexOf('AxiosError: timeout') !== -1){
                    Message({
                        message: '接口请求超时',
                        type: 'error'
                    });
                    return Promise.reject(error)
                }else{
                    Message({
                        message: '接口请求失败',
                        type: 'error'
                    });
                }
            }else if (
                error.response.data instanceof Blob &&
                error.response.data.type.toLowerCase().indexOf('json') !== -1
            ) {
                const reader = new FileReader()
                reader.readAsText(error.response.data, 'utf-8')
                reader.onload = function(e) {
                const errorMsg = JSON.parse(reader.result).message
                Message({
                    message: errorMsg,
                    type: 'error'
                });
                }
            } else {
                let code = 0
                try {
                    code = error.response.data.status
                    switch(code){
                    case 401:
                        
                        break;
                    case 403:
                        
                        break;
                    default:
                        const errorMsg = error.response.data.message ? error.response.data.message : '接口请求失败'
                        Message({
                            message: errorMsg,
                            type: 'error'
                        });
                        break;
                    }
                } catch (e) {
                    Message({
                        message: '接口请求失败',
                        type: 'error'
                    });
                }
            }
            return Promise.reject(error)
            }
        )
    },
    mounted(){
        this.getList()
    },
    methods:{
        getList() {
            this.axios.get('/plateType/queryPlateTypeList',{params:{pageNum:1,pageSize:20}}).then(res=>{
                this.typeList = res.data.data.list.map(val=>{
                    val.typePhoto = val.typePhoto ? this.$store.state.imgUrl + "/images/" + val.typePhoto : null;
                    // let str = './assets/image/' + val.typeIcon.split('image/')[1]
                    // val.typeIcon = require(str)
                    if(val.typeId == 2){
                        val.typeIcon = require('./assets/image/yjbyd.png')
                    }else if(val.typeId == 3){
                        val.typeIcon = require('./assets/image/byddj.png')
                    }else if(val.typeId == 4){
                        val.typeIcon = require('./assets/image/bydzx.png')
                    }else if(val.typeId == 5){
                        val.typeIcon = require('./assets/image/ywbyd.png')
                    }else if(val.typeId == 6){
                        val.typeIcon = require('./assets/image/bydyk.png')
                    }
                    return val
                })
                console.log("分类表",this.typeList);
            }).catch(err=>{
                console.log('报错')
            })
        },
    },
  }
</script>

<style lang="less">
@media (min-width: 751px) {
    #header {
        display: block;
    }
    .header_h5{
        display: none;
    }
    html{
        width: 100%;
        height: 100%;
    }
    body{
        width: 100%;
        height: 100%;
    }
    #app{
        width: 100%;
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        position: relative;
        // &::-webkit-scrollbar {
        //     width: 0;
        //     height: 0;
        //     color: transparent;
        //     display: none;
        // }   
    }
}
@media (max-width: 750px) {
    #header {
        display: none;
    }
    .header_h5{
        display: block;
    }
    html{
        width: 100%;
        height: 100%;
    }
    body{
        position: fixed !important;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        overflow:hidden;
    }
    #app{
        width: 100%;
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        position: relative;
        &::-webkit-scrollbar {
            width: 0;
            height: 0;
            color: transparent;
            display: none;
        }   
    }
}
.t_ellipsis{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.t_ellipsis_2{
    word-break: break-all;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
}
.t_ellipsis_4{
    word-break: break-all;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    overflow: hidden;
}
.h_100{
    height:100%
}
.w_100{
    width: 100%;
}
.d_flex{
    display: flex;
}
.jc_end{
    justify-content: flex-end;
}
</style>