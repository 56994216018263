<template>
  <div class="a">
    <div>
      <div style="width: 20%;margin: 0 auto">
        <div style="height: 300px;"></div>
        <el-form
          :label-position="labelPosition"
          label-width="80px"
          :model="formLabelAlign"
        >
          <el-form-item label="用户名">
            <el-input v-model="formLabelAlign.name"></el-input>
          </el-form-item>
          <el-form-item label="密码">
            <el-input type="password" v-model="formLabelAlign.region"></el-input>
          </el-form-item>
        </el-form>
        <el-button type="primary" style="margin-left:45%" @click="login">登录</el-button>
        
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      labelPosition: "right",
      formLabelAlign: {
        name: "",
        region: "",
        type: "",
      },
    };
  },
  mounted(){
      
  },
  methods:{
      login(){
          // console.log( typeof this.formLabelAlign.name , typeof this.formLabelAlign.region)
          this.axios.post(`/user/login?account=${this.formLabelAlign.name}&password=${this.formLabelAlign.region}`).then(res=>{
              // console.log(res)
              if(res.status==200){
                console.log("登陆",res.data)
                    this.axios.defaults.headers.common['token'] = res.data.data
                    localStorage.setItem("BYD_TOKEN_VALUE",res.data.data)
                  this.$router.push('/pageAdmin/user')
                  localStorage.setItem('islogin',1)
                  sessionStorage.setItem('islogin',1)
              }
          }).catch(err=>{
            alert('用户名或密码错误')
            this.region =''
          })
      }
  }
};
</script>

<style scoped>
.a{
  width: 100%;
  height: 60rem;
  background: url(../assets/image/login_bg.jpg);
  background-size: 100% 100%;
}
</style>