<template>
    <div>
        <!-- h5显示 -->
        <div id="container_h5">
            <div class="container_h5_header">
                <van-swipe
                    class="my-swipe"
                    :autoplay="3000"
                    indicator-color="white"
                    v-if="dataArr1.length > 0"
                >
                    <van-swipe-item
                        v-for="(item, index) in dataArr1"
                        :key="index"
                    >
                        <img
                            :src="item.imagePath"
                            alt=""
                        />
                    </van-swipe-item>
                </van-swipe>
                <img
                    src="../assets/image/size.png"
                    style="
                        position: absolute;
                        left: 5%;
                        bottom: 15%;
                        width: 25%;
                        height: auto;
                        z-index: 1;
                    "
                    alt=""
                />
                <div class="weather_dom">
                    <p class="city">
                        <span>安新</span>
                        <span style="margin: 0 4px">{{
                            weather.weatherForecast
                        }}</span>
                        <span>{{ weather.weatherTemperature }}°C</span>
                    </p>
                    <p>{{ weather.weatherDirect }}{{ weather.weatherPower }}</p>
                    <p>空气质量:{{ weather.weatherAqi }}</p>
                    <p>湿度{{ weather.weatherHumidity }}%</p>
                </div>
            </div>
            <van-notice-bar class="under_h5" @click="urlTo(news[0].noticeId,'-1')" scrollable left-icon="volume-o" :text="noticeNews" v-if="news.length == 1">
            </van-notice-bar>
            <van-notice-bar class="under_h5" left-icon="volume-o" :scrollable="false" v-if="news.length > 1">
                <van-swipe vertical class="notice-swipe" :autoplay="3000" :show-indicators="false" style="height:40px">
                    <van-swipe-item v-for="(item,index) in news" :key="index" @click="urlTo(item.noticeId,'-1')">
                        <span style="line-height:40px;width:100%;overflow:hidden;text-overflow: ellipsis;white-space: nowrap;display:block">{{item.title}}</span>
                    </van-swipe-item>
                </van-swipe>
            </van-notice-bar>
            <!-- 游进白洋淀 -->
            <div class="container_h5_slide">
                <div class="container_h5_title">
                    <h3 style="font-weight: bold">{{somllimg.typeTitle || '-'}}</h3>
                    <p style="12px">
                        {{somllimg.typeSynopsis || '-'}}
                    </p>
                </div>
                <vue-seamless-scroll
                    :data="somllimg.imageSmallList"
                    :class-option="initOption"
                    style="height: 96px; overflow: hidden"
                >
                    <ul class="seamless_ul">
                        <li v-for="(item, index) in somllimg.imageSmallList" :key="index">
                            <img
                                :src="item.imagePath"
                            />
                            <p>{{ item.imageTitle }}</p>
                        </li>
                    </ul>
                </vue-seamless-scroll>
                <div class="video_dom">
                    <div @click="videoPlay" style="position:relative" v-show="!idPlaying">
                        <img src="../assets/image/video_home.png" style="width:100%;height:auto" alt="">
                        <div class="play_dom">
                            <van-icon name="play-circle" />
                        </div>
                    </div>
                    <van-sticky :offset-top="46" v-show="idPlaying" v-if="videoInfo.videoPath">
                        <video ref="h5_video" loop type="video/mp4" width="100%" height="auto" @play="idPlaying = true" @pause="idPlaying = false" controls="autoplay" poster="../assets/image/video_home.png" controlslist="nodownload" :src="videoInfo.videoPath"></video>
                    </van-sticky>
                    <div class="video_dom_text">
                        <h3>{{videoInfo.videoTitle}}</h3>
                        <div style="text-align:left" v-html="videoInfo.videoIntro">
                            <!-- 白洋五日看回花，馥馥莲芳入梦来，这明代诗人鹿善继在白洋淀尚莲时留下的赞叹。白洋淀被称为“华北明珠”，亦有“北地西湖北国江南”之誉，是著名的国家5A级旅游景区。白洋淀位于河北省中部，主体在雄安新区安新县境内，地处京、津、冀腹地，是华北地区最大的淡水湖泊，总面积366平方公里。 -->
                        </div>
                    </div>
                </div>
                <!-- 党建及资讯 -->
                <div class="container_h5_card">
                    <!-- 党建 -->
                    <div class="container_h5_title" v-if="partys.partyList.length > 0">
                        <h3 style="font-weight: bold">{{partys.plateType.typeTitle || '-'}}</h3>
                    </div>
                    <div class="card_box" v-if="partys.partyList.length > 0">
                        <h4 class="card_box_title">
                            <span style="color: red">{{partys.plateType.typeTitle || '-'}}</span>
                            <a href="#/list" style="font-size:12px">
                                <span>查看所有</span>
                                <span style="color: red">>></span>
                            </a>
                        </h4>
                        <div class="card_list">
                            <router-link :to="{ name: 'Details',query: { ID: item.partyId },}" class="card_cell" v-for="(item, index) of partys.partyList" :key="index">
                                <div class="card_img_dom">
                                    <img class="card_cell_img" :src="item.partyPhoto" alt=""/>
                                </div>
                                <div class="card_cell_text">
                                    <p class="t_ellipsis" style="color:#f00">{{item.partyTitle}}</p>
                                    <p class="t_ellipsis">{{item.partyAddTime}}</p>
                                    <div class="card_cell_text_body t_ellipsis_2">
                                        {{item.partySynopsis}}
                                    </div>
                                </div>
                            </router-link>
                        </div>
                    </div>
                    <!-- 资讯 -->
                    <div class="container_h5_title" v-if="grnn.newsList.length > 0">
                        <h3 style="font-weight: bold">{{grnn.plateType.typeTitle || '-'}}</h3>
                    </div>
                    <div class="card_box" v-if="grnn.newsList.length > 0">
                        <h4 class="card_box_title">
                            <span style="color: green">{{grnn.plateType.typeTitle || '-'}}</span>
                            <a href="#/list" style="font-size:12px">
                                <span>查看所有</span>
                                <span style="color: green">>></span>
                            </a>
                        </h4>
                        <div class="card_list">
                            <router-link :to="{ name: 'Details',query: { ID: item.newsId },}" class="card_cell" v-for="(item, index) of grnn.newsList" :key="index">
                                <div class="card_img_dom">
                                    <img class="card_cell_img" :src="item.newsPhoto" alt=""/>
                                </div>
                                <div class="card_cell_text">
                                    <p class="t_ellipsis" style="color:green">{{item.newsTitle}}</p>
                                    <p class="t_ellipsis">{{item.newsAddTime}}</p>
                                    <div class="card_cell_text_body t_ellipsis_2">
                                        {{item.newsSynopsis}}
                                    </div>
                                </div>
                            </router-link>
                        </div>
                    </div>
                </div>

                <!-- 游玩白洋淀 -->
                <div class="fiche">
                    <div class="container_h5_title">
                        <h3 style="font-weight: bold">{{modul.plateType.typeTitle || '-'}}</h3>
                        <p style="12px">
                            {{modul.plateType.typeSynopsis || '-'}}
                        </p>
                    </div>
                    <div class="fiche_list">
                        <router-link :to="{ path: '/lotus', query: { ID: item.scenicId } }" class="fiche_cell" v-for="(item, index) of modul.scenicList" :key="index">
                            <img style="width: 100%" class="fiche_cell_img" :src="item.scenicPhoto"alt=""/>
                            <div class="fiche_cell_text">
                                <p>{{ item.scenicTitle }}</p>
                                <p :class="item.scenicShow == 1 ? 'c_green' : 'c_red'">{{item.scenicShow == 1 ? "当前园区开放" : "当前园区关闭"}}</p>
                                <div class="t_ellipsis_2">
                                    {{ item.scenicSynopsis }}
                                </div>
                            </div>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>

        <div id="container" class="home normal">
            <!-- <div id="container" class="home normal"> -->
                <div class="focus">
                    <!-- 轮播图 -->
                    <lunbo :dataArr1="dataArr1" v-if="dataArr1.length > 0"></lunbo>
                    <!-- logo图 -->
                    <div
                        class="item_img_logo"
                        style="
                            z-index: 999;
                            width: 25%;
                            height: 20%;
                            position: absolute;
                            left: 5%;
                            bottom: 15%;
                        "
                    >
                        <img src="../assets/image/size.png" alt="" style="width:100%;height:auto"/>
                    </div>
                    <!-- 信息 -->
                    <div class="wind">
                        <p class="weather">城市：安新</p>
                        <!-- {{weather.weatherCity}} -->
                        <p class="weather" style="color: #fff">
                            {{ weather.weatherDate }}
                        </p>
                        <div class="weather">
                            <p>{{ weather.weatherForecast }}</p>
                            <img
                                src="../assets/image/weather.png"
                                class="weatherimg"
                                alt=""
                            />
                        </div>
                        <div class="weather">
                            <p>温度</p>
                            <p class="text_item">
                                {{ weather.weatherTemperature }}°C
                            </p>
                        </div>
                        <div class="weather">
                            <p>风向</p>
                            <p class="text_item">
                                {{ weather.weatherDirect }}
                            </p>
                        </div>
                        <div class="weather">
                            <p>风力</p>
                            <p class="text_item">
                                {{ weather.weatherPower }}
                            </p>
                        </div>
                        <div class="weather">
                            <p>空气质量</p>
                            <p class="text_item">
                                {{ weather.weatherAqi }}
                            </p>
                        </div>
                        <div class="weather">
                            <p>湿度</p>
                            <p class="text_item">
                                {{ weather.weatherHumidity }}%
                            </p>
                        </div>
                    </div>
                    <!-- 公告 -->
                    <van-notice-bar style="height:60px;background-color:rgba(0,0,0,0.25);margin-top:-60px;z-index:2;font-size:20px" @click="urlTo(news[0].noticeId,'-1')" color="#FFFFFF" scrollable left-icon="volume-o" :text="noticeNews" v-if="news.length == 1">
                    </van-notice-bar>
                    <van-notice-bar style="height:60px;background-color:rgba(0,0,0,0.25);margin-top:-60px;z-index:2" color="#FFFFFF" left-icon="volume-o" :scrollable="false" v-if="news.length > 1">
                        <van-swipe vertical class="notice-swipe" :autoplay="3000" :show-indicators="false" style="height:60px">
                            <van-swipe-item v-for="(item,index) in news" :key="index" @click="urlTo(item.noticeId,'-1')">
                                <span style="line-height:60px;width:100%;overflow:hidden;text-overflow: ellipsis;white-space: nowrap;display:block;font-size:20px">{{item.title + item.createTime}}</span>
                            </van-swipe-item>
                        </van-swipe>
                    </van-notice-bar>
                    <!-- <div class="under" style="padding:0 5%;display:flex;align-items: center;">
                        <span style="padding-right:2%">公告</span>
                        <div style="flex:1;overflow:hidden;height:100%">
                            <fontscroll :val="news"></fontscroll>
                        </div>
                    </div> -->
                </div>
            <!-- </div> -->
        </div>
        <div style="max-width:1920px;margin:auto;padding:0 15px;box-sizing:border-box">
            <!-- 游进白洋淀 -->
            <div id="menu1" class="slideshow">
                <h1>{{somllimg.typeTitle || '-'}}</h1>
                <p>{{somllimg.typeSynopsis || '-'}}</p>
                <div class="scrollBox">
                    <vue-seamless-scroll
                        :data="somllimg.imageSmallList"
                        :class-option="initOption"
                        style="height: 220px; overflow: hidden"
                    >
                        <ul id="all" :style="'width:'+246 * somllimg.imageSmallList.length+'px'">
                            <li v-for="(item, index) in somllimg.imageSmallList" :key="index" @click="urlTo(item.imageId,2)">
                                <div class="bord">
                                    <span></span>
                                </div>
                                <div class="center">
                                    <img :src="item.imagePath"/>
                                </div>
                                <div class="bord">
                                    {{ item.imageTitle }}
                                </div>
                            </li>
                        </ul>
                    </vue-seamless-scroll>
                </div>
            </div>
            <!-- 视频介绍 -->
            <div class="videos" style="width: 100%">
                <video
                    loop
                    type="video/mp4"
                    width="680"
                    height="400"
                    controls="autoplay"
                    poster="../assets/image/video_home.png"
                    controlslist="nodownload"
                    :src="videoInfo.videoPath"
                ></video>

                <div class="vertical">
                    <h1 style="text-align:center;margin-left:12px">{{videoInfo.videoTitle}}</h1>
                    <div v-html="videoInfo.videoIntro" style="line-height:40px"></div>
                    <!-- <p>INTRODUCTION TO SCENIC SPOTS</p>
                    <div>
                        白洋五日看回花，馥馥莲芳入梦来，这明代诗人鹿善继在白洋淀尚莲时留下的赞叹。白洋淀被称为“华北明珠”，亦有“北地西湖北国江南”之誉，是著名的国家5A级旅游景区。白洋淀位于河北省中部，主体在雄安新区安新县境内，地处京、津、冀腹地，是华北地区最大的淡水湖泊，总面积366平方公里
                    </div> -->
                </div>
            </div>

            <!-- 党建及资讯 -->
            <div id="zx" class="party_information">
                <!-- 党建 -->
                <div class="party" v-if="partys.partyList.length > 0" style="padding-right:15px;box-sizing:border-box">
                    <p class="title">{{partys.plateType.typeTitle || '-'}}</p>
                    <div class="party_text" :style="partys.plateType.typePhoto ? 'background-image: url('+partys.plateType.typePhoto+');' : 'background-image: url(require(`../../../assets/image/party.png)`)'">
                        <div class="xys">
                            <div class="p_q">
                                <div style="margin-right: 20px">
                                    <span style="color: red; font-size: 16px"
                                        >{{partys.plateType.typeTitle || '-'}}</span
                                    >
                                    <!-- <span style="margin-left: 10px;">旅行法律法规</span> -->
                                </div>
                                <a href="#/list" style="margin-right: 10px">
                                    查看所有
                                    <span style="color: red">>></span>
                                </a>
                            </div>
                            <router-link
                                :to="{
                                    name: 'Details',
                                    query: { ID: item.partyId },
                                }"
                                class="party_one"
                                v-for="(item, index) of partys.partyList"
                                :key="index"
                            >
                                <div style="width: 40%; margin-right: 10px">
                                    <img
                                        style="height: 6.8vw; width: 100%"
                                        :src="item.partyPhoto"
                                        alt=""
                                    />
                                </div>
                                <div style="width: 55%;">
                                    <p class="t_ellipsis" style="color: red;margin: 0;">
                                        {{ item.partyTitle }}
                                    </p>
                                    <p class="t_ellipsis" style="color: gray;font-size: 14px;font-weight: 600;margin: 0;">
                                        {{ item.partyAddTime }}
                                    </p>
                                    <div class="party_one_ellipsis">
                                        {{ item.partySynopsis }}
                                    </div>
                                </div>
                            </router-link>
                        </div>
                    </div>
                </div>

                <!-- 资讯 -->
                <div class="information" v-if="grnn.newsList.length > 0" style="padding-left:15px;box-sizing:border-box">
                    <p class="title">{{grnn.plateType.typeTitle || '-'}}</p>
                    <div class="party_texts" :style="grnn.plateType.typePhoto ? 'background-image: url('+grnn.plateType.typePhoto+');' : 'background-image: url(require(`../../../assets/image/information.png)`)'">
                        <div class="xys">
                            <div class="p_q">
                                <div style="margin-right: 20px">
                                    <span style="color: green; font-size: 16px"
                                        >{{grnn.plateType.typeTitle || '-'}}</span
                                    >
                                    <!-- <span style="margin-left: 10px;">旅行法律法规</span> -->
                                </div>
                                <a href="#/lists" style="margin-right: 10px">
                                    查看所有
                                    <span style="color: green">>></span>
                                </a>
                            </div>
                            <router-link
                                :to="{
                                    name: 'Detailsy',
                                    query: { ID: item.newsId },
                                }"
                                class="party_one"
                                v-for="(item, index) of grnn.newsList"
                                :key="index"
                            >
                                <div style="width: 40%; margin-right: 10px">
                                    <img
                                        style="height: 6.8vw; width: 100%"
                                        :src="item.newsPhoto"
                                        alt=""
                                    />
                                </div>
                                <div style="width: 55%;">
                                    <p class="t_ellipsis" style="color: green;margin: 0;">
                                        {{ item.newsTitle }}
                                    </p>
                                    <p class="t_ellipsis" style="color: gray;font-size: 14px;font-weight: 600;margin: 0;">
                                        {{ item.newsAddTime }}
                                    </p>
                                    <!-- <p style="font-size: 10px; margin-bottom: 5px">2020</p> -->
                                    <div class="party_one_ellipsis">
                                        {{ item.newsSynopsis }}
                                    </div>
                                </div>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>

            <!-- 游玩白洋淀 -->
            <div id="menu2" class="play" style="padding-top:20px">
                <h1>{{modul.plateType.typeTitle || '-'}}</h1>
                <p>{{modul.plateType.typeSynopsis || '-'}}</p>
                <div class="play_list">
                    <router-link :to="{ path: '/lotus', query: { ID: item.scenicId } }" class="a" v-for="(item, index) of modul.scenicList" :key="index" style="width:calc(33.33% - 18px)">
                        <div class="list_item">
                            <div style="width: 100%">
                                <img
                                    style="width: 100%"
                                    class="radius"
                                    :src="item.scenicPhoto"
                                    alt=""
                                />
                            </div>
                            <p style="margin-left: 10px">{{ item.scenicTitle }}</p>
                            <p
                                style="
                                    font-size: 8px;
                                    color: gray;
                                    margin-top: 5px;
                                    margin-left: 10px;
                                "
                            >
                                <span
                                    :class="
                                        item.scenicShow == 1
                                            ? 'list_span'
                                            : 'list_spans'
                                    "
                                >
                                    {{
                                        item.scenicShow == 1
                                            ? "当前园区开放"
                                            : "当前园区关闭"
                                    }}
                                </span>
                            </p>
                            <div class="pdl">
                                {{ item.scenicSynopsis }}
                            </div>
                        </div>
                    </router-link>
                </div>
            </div>

            <!-- 我要购票 -->
            <div id="gp" class="serve_tourist">
                <p
                    style="
                        text-align: center;
                        font-size: 27px;
                        color: black;
                        font-weight: 600;
                    "
                >
                    {{gpInfo.typeTitle}}
                </p>
                <div class="box" style="margin-top:25px">
                    <img :src="gpInfo.typePhoto" alt="" style="width:100%;height:auto"/>
                </div>
            </div>
        </div>

        <!-- 返回顶部 -->
        <span id="up" @click="scrollToTop">
            <img src="../assets/image/up.png" />
        </span>
    </div>
</template>

<style>
@import "../assets/css/slideshow.css";
@import "../assets/css/index_response.css";
@import "../assets/css/global_response.css";
@import "../assets/css/global.css";
</style>

<script>
import videojs from "video.js";
import "videojs-contrib-hls";
import tables from "../components/video";
// import fontscroll from "../components/fontscroll.vue";
import lunbo from "../components/banner.vue";
export default {
    components: {
        tables,
        lunbo,
        // fontscroll,
    },
    data() {
        return {
            dataArr1: [], //大轮播
            videoInfo: {},
            news: [],
            noticeNews: "",
            // inp: "",
            partys:{    //党建
                partyList:[],
                plateType:{}
            },
            grnn:{      //资讯
                newsList:[],
                plateType:{}
            },
            modul: {
                scenicList:[],
                plateType:{}
            }, //游玩白洋淀
            somllimg: {
                imageSmallList:[],
                typeTitle:'',
                typeSynopsis:''
            }, //小轮播图片
            weather: {},
            gpInfo:{},      //购票流程

            idPlaying:false,
        };
    },

    mounted() {
        this.init()
        // this.getviedo()
        // console.log(this.$store.state.imgUrl);
        // this.getnews();
        this.party();
        this.gren();
        // this.newdate();
        // this.somliamge();
        this.module();
    },
    methods: {
        init(){
            this.axios.get("/init/").then((res) => {
                this.dataArr1 = res.data.data.imageBigList.map(val=>{
                    val.imagePath = this.$store.state.imgUrl + "/images/" + val.imagePath
                    return val
                });
                    console.log(22,this);
                this.weather = res.data.data.weather;
                this.weather.isOpen =
                    res.data.data.weather.isOpen == 1 ? "开放" : "暂停服务";
                    res.data.data.imageSmallList.imageSmallList.forEach(val=>{val.imagePath = this.$store.state.imgUrl + "/images/" + val.imagePath})
                this.somllimg = res.data.data.imageSmallList;

                this.news = res.data.data.noticeRoll;
                this.noticeNews = res.data.data.noticeRoll[0].title;

                res.data.data.video.videoPath = this.$store.state.imgUrl + "/images/" + res.data.data.video.videoPath
                this.videoInfo = res.data.data.video

                res.data.data.plateType.typePhoto = this.$store.state.imgUrl + "/images/" + res.data.data.plateType.typePhoto
                this.gpInfo = res.data.data.plateType

            }).catch((err) => {
                console.error(err);
            });
        },
        videoPlay(){
            this.idPlaying = true
            this.$refs.h5_video.play()
        },
        scrollToTop(){
            document.getElementById('app').scrollTop = 0
            // console.log(1111,dom,dom.scrollTop)
        },
        module() {
            this.axios
                .get("/scenic/querySixTitleScenicList")
                .then((res) => {
                    // console.log(res);
                    res.data.data.scenicList.map(val=>{
                        val.scenicPhoto = this.$store.state.imgUrl + "/images/" + val.scenicPhoto
                        return val
                    })
                    
                    this.modul = res.data.data;
                })
                .catch((err) => {
                    // console.error(err);
                });
        },
        // somliamge() {
        //     this.axios
        //         .get("/init")
        //         .then((res) => {
        //             this.dataArr1 = res.data.data.imageBigList.map(val=>{
        //                 val.imagePath = this.$store.state.imgUrl + "/images/" + val.imagePath
        //                 return val
        //             });
        //                 console.log(22,this);
        //             this.weather = res.data.data.weather;
        //             this.weather.isOpen =
        //                 res.data.data.weather.isOpen == 1 ? "开放" : "暂停服务";
        //                 res.data.data.imageSmallList.imageSmallList.forEach(val=>{val.imagePath = this.$store.state.imgUrl + "/images/" + val.imagePath})
        //             this.somllimg = res.data.data.imageSmallList;
        //         })
        //         .catch((err) => {
        //             console.error(err);
        //         });
        // },
        // newdate() {
        //     this.date = new Date();
        // },
        // getviedo(){
        //   this.axios.get('/video/queryVideo').then((res)=>{
        //     // console.log(res)
        //     this.videourl=res.data.data
        //     // this.$set(this.videourl,'url',res.data.data.videoPath)
        //   })
        // },
        // getnews() {
        //     this.axios.get("/init").then((res) => {
        //         this.news = res.data.data.noticeRoll;
        //         // console.log(res.data.data.noticeRoll.title,res.data.data.noticeRoll.createTime);
        //         this.noticeNews =
        //             res.data.data.noticeRoll[0].title +
        //             res.data.data.noticeRoll[0].createTime;
        //         // console.log(this.news)
        //     });
        // },
        // inps() {
        //     this.axios
        //         .post("/proposal/addProposal", {
        //             proposalContent: this.inp,
        //             proposalUser: "游客",
        //         })
        //         .then(alert("待审核中..."), (this.inp = ""));
        // },
        // but() {
        //     this.inps();
        // },
        party() {
            this.axios.get("/party/queryThreeTitleParty").then((res) => {
                // console.log(res);
                res.data.data.partyList.map(val=>{
                    val.partyPhoto = this.$store.state.imgUrl + "/images/" + val.partyPhoto
                    return val
                })
                res.data.data.plateType.typePhoto = this.$store.state.imgUrl + "/images/" + res.data.data.plateType.typePhoto
                this.partys = res.data.data;
                // console.log(this.partys)
            });
        },
        gren() {
            this.axios.get("/news/queryThreeTitleNews").then((res) => {
                console.log(11,res)
                res.data.data.newsList.map(val=>{
                    val.newsPhoto = this.$store.state.imgUrl + "/images/" + val.newsPhoto
                    return val
                })
                res.data.data.plateType.typePhoto = this.$store.state.imgUrl + "/images/" + res.data.data.plateType.typePhoto
                this.grnn = res.data.data;
            });
        },
        urlTo(id,type){
            this.$router.push({path:"/noticedetail",query:{id,type}})
        }
    },
    computed: {
        initOption() {
            return {
                step: 1, // 数值越大速度滚动越快
                limitMoveNum: this.somllimg.imageSmallList.length, // 开始无缝滚动的数据量 this.dataList.length
                hoverStop: true, // 是否开启鼠标悬停stop
                direction: 2, // 0向下 1向上 2向左 3向右
                openWatch: true, // 开启数据实时监控刷新dom
                singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
                singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
                waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
            };
        },
        propShow(){
            return this.$store.state.propShow
        },
    },
    watch:{
        propShow(){
            this.idPlaying = false
            this.$refs.h5_video.pause()
        },
    },
};
</script>

<style lang="less">
.scrollBox {
    margin: 0 auto;
    margin-top: 60px;
    width: 100%;
    height: 220px;
    padding-top: 12px;
    padding-bottom: 12px;
    box-sizing: border-box;
    background-color: #fff;
    ul {
        height: 220px;
        display: flex;
        li {
            width: 196px;
            height: 200px;
            cursor: pointer;
            .bord {
                display: flex;
                justify-content: space-around;
                span {
                    display: inline-block;
                    width: 10px;
                    height: 13px;
                    background-color: #ffffff;
                }
            }
            .center {
                width: 196px;
                height: 135px;
                margin-top: 12px;
                margin-bottom: 12px;
                background-color: #000000;
                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}
@media (min-width: 751px) {
    #container_h5 {
        display: none;
    }
    .van-notice-bar__left-icon{
        font-size: 20px !important;
        color: wheat;
    }
    // #container,
    // #menu1,.videos,#zx,#menu2 {
    //     display: block;
    // }
}
@media (max-width: 750px) {
    #container,
    #menu1,.videos,#zx,#menu2 {
        display: none;
    }
    // #container_h5 {
    //     display: block;
    // }
}
@media (min-width: 1800px){
    .party_one_ellipsis{
        height:101px;
        -webkit-line-clamp: 6;
    }
}
@media (max-width: 1800px){
    .party_one_ellipsis{
        height:84px;
        -webkit-line-clamp: 5;
    }
}
@media (max-width: 1527px){
    .party_one_ellipsis{
        height:68px;
        -webkit-line-clamp: 4;
    }
}
@media (max-width: 1270px){
    .party_one_ellipsis{
        height:51px;
        -webkit-line-clamp: 3;
    }
}
@media (max-width: 1038px){
    .party_one_ellipsis{
        height:34px;
        -webkit-line-clamp: 2;
    }
}
.party_one_ellipsis{
    font-size: 12px;
    width: 100%;
    line-height:1.4;
    word-break: break-all;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
#container_h5 {
    background-color: #F2F2F2;
    .container_h5_header {
        position: relative;
        .weather_dom {
            position: absolute;
            right: 0;
            bottom: 0;
            padding: 2px 8px;
            border-top-left-radius: 4px;
            background-color: rgba(0, 0, 0, 0.25);
            color: #ffffff;
            font-size: 12px;
        }
    }
    .container_h5_slide {
        text-align: center;
        .container_h5_title {
            padding: 20px 40px;
        }
        .seamless_ul {
            display: flex;
            white-space: nowrap;
            li {
                width: 96px;
                margin-right: 10px;
                img {
                    width: 96px;
                    height: 54px;
                }
            }
        }
        .video_dom{
            .play_dom{
                position:absolute;
                width: 40px;
                height:40px;
                top: 50%;
                left: 50%;
                margin-left: -20px;
                margin-top: -20px;
                font-size: 46px;
            }
            .video_dom_text{
                background: url(../assets/image/video_bg.png);
                background-repeat: no-repeat;
                background-size: cover;
                line-height: 2;
                writing-mode: vertical-lr;
                width: 100%;
                padding: 10px 40px;
                height: 260px;
            }
        }   
        .container_h5_card{
            padding-top:3vw;
            width: 94vw;
            margin-left: 3vw;
            .card_box{
                border-radius: 12px;
                border: 1px solid #dfdfdf;
                background-color: #ffffff;
                padding: 2vw;
                .card_box_title{
                    text-align:left;
                    display: flex;
                    justify-content: space-between;
                }
            }
            .card_list{
                .card_cell{
                    display: flex;
                    justify-content: space-between;
                    text-align: left;
                    padding: 5px 0;
                    &+.card_cell{
                        border-top: 1px solid #ddd;
                    }
                    .card_img_dom{
                        width: 33vw;
                        .card_cell_img{
                            width: 33vw;
                            height: 20.25vw;
                        }
                    }
                    .card_cell_text{
                        width: 52vw;
                        .card_cell_text_body{
                            font-size: 12px;
                            line-height:1.4;
                        }
                    }
                }
            }
        }
        .fiche{
            width: 94vw;
            margin-left: 3vw;
            .fiche_list{
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                .fiche_cell{
                    width: 46.5vw;
                    background-color: #FFFFFF;
                    border-radius: 10px;
                    overflow: hidden;
                    margin-bottom: 1vw;
                    text-align: left;
                    box-shadow: 0 0 2px grey;
                    .fiche_cell_img{
                        width: 100%;
                        height: 24vw;
                    }
                    .fiche_cell_text{
                        padding: 6px 10px;
                    }
                }
            }
        }
    }
}



</style>
