<template>
  <div>
    <el-tabs type="border-card" value="0" @tab-click="changeTypeCli">
        <el-tab-pane v-for="(item,index) in typeList" :key="item.typeId">
            <span slot="label">{{item.typeTitle || '-'}}</span>
            <el-tabs v-model="activeName" :key="index">
                <el-tab-pane label="数据表" :name="'first'+index" v-if="item.typeId != 6">
                    <el-button type="primary" icon="el-icon-plus" @click="addCli(item.typeId)">新增</el-button>
                    <el-table :data="list" :header-cell-style="{textAlign:'center',backgroundColor:'#f5f7fa'}" stripe tooltip-effect="light" style="width: 100%;margin-top:14px">
                        <!-- 小轮播 -->
                        <el-table-column prop="imageId" label="ID" width="120" align="center" v-if="item.typeId == 2">
                        </el-table-column>
                        <el-table-column prop="imageTitle" align="center" label="图片名称" show-overflow-tooltip min-width="200" v-if="item.typeId == 2">
                        </el-table-column>
                        <el-table-column prop="imageSerial" align="center" label="排序" show-overflow-tooltip width="80" v-if="item.typeId == 2">
                        </el-table-column>
                        <el-table-column label="图片" width="100" prop="imagePath" v-if="item.typeId == 2">
                            <template slot-scope="scope">
                            <el-popover placement="top-start" title="" trigger="hover">
                                <img :src="scope.row.imagePath" alt="" style="width: 300px;" />
                                <img
                                slot="reference"
                                :src="scope.row.imagePath"
                                style="width: 60px;"
                                />
                            </el-popover>
                            </template>
                        </el-table-column>
                        
                        <!-- 党建 -->
                        <el-table-column prop="partyId" label="ID" width="120" align="center" v-if="item.typeId == 3">
                        </el-table-column>
                        <el-table-column prop="partyTitle" label="标题" show-overflow-tooltip width="160" v-if="item.typeId == 3">
                        </el-table-column>
                        <el-table-column prop="partySynopsis" label="概括" min-width="200" v-if="item.typeId == 3">
                            <template slot-scope="scope">
                                <el-tooltip class="item" effect="dark" placement="top">
                                    <div slot="content" v-html="ToBreak(scope.row.partySynopsis)" />
                                    <div class="oneLine">
                                        {{ scope.row.partySynopsis }}
                                    </div>
                                </el-tooltip>
                            </template>
                        </el-table-column>
                        <el-table-column label="图片" width="100" prop="partyPhoto" v-if="item.typeId == 3">
                            <template slot-scope="scope">
                            <el-popover placement="top-start" title="" trigger="hover">
                                <img :src="scope.row.partyPhoto" alt="" style="width: 300px;" />
                                <img
                                slot="reference"
                                :src="scope.row.partyPhoto"
                                style="width: 60px;"
                                />
                            </el-popover>
                            </template>
                        </el-table-column>

                        <!-- 资讯 -->
                        <el-table-column prop="newsId" label="ID" width="120" align="center" v-if="item.typeId == 4">
                        </el-table-column>
                        <el-table-column prop="newsTitle" label="标题" show-overflow-tooltip width="160" v-if="item.typeId == 4">
                        </el-table-column>
                        <el-table-column prop="newsSynopsis" label="概括" min-width="200" v-if="item.typeId == 4">
                            <template slot-scope="scope">
                                <el-tooltip class="item" effect="dark" placement="top">
                                    <div slot="content" v-html="ToBreak(scope.row.newsSynopsis)" />
                                    <div class="oneLine">
                                        {{ scope.row.newsSynopsis }}
                                    </div>
                                </el-tooltip>
                            </template>
                        </el-table-column>
                        <el-table-column label="图片" width="100" prop="newsPhoto" v-if="item.typeId == 4">
                            <template slot-scope="scope">
                            <el-popover placement="top-start" title="" trigger="hover">
                                <img :src="scope.row.newsPhoto" alt="" style="width: 300px;" />
                                <img
                                slot="reference"
                                :src="scope.row.newsPhoto"
                                style="width: 60px;"
                                />
                            </el-popover>
                            </template>
                        </el-table-column>


                        <!-- 游玩 -->
                        <el-table-column prop="scenicId" label="ID" width="120" align="center" v-if="item.typeId == 5">
                        </el-table-column>
                        <el-table-column prop="scenicTitle" label="标题" show-overflow-tooltip width="160" v-if="item.typeId == 5">
                        </el-table-column>
                        <el-table-column prop="scenicShow" label="状态" width="160" v-if="item.typeId == 5" align="center">
                            <template slot-scope="scope">
                                <el-tag :type="scope.row.scenicShow == 1 ? 'success' : 'info'">{{scope.row.scenicShow == 1 ? '开启' : '关闭'}}</el-tag>
                            </template>
                        </el-table-column>
                        <el-table-column prop="scenicSynopsis" label="概括" min-width="200" v-if="item.typeId == 5">
                            <template slot-scope="scope">
                                <el-tooltip class="item" effect="dark" placement="top">
                                    <div slot="content" v-html="ToBreak(scope.row.scenicSynopsis)" />
                                    <div class="oneLine">
                                        {{ scope.row.scenicSynopsis }}
                                    </div>
                                </el-tooltip>
                            </template>
                        </el-table-column>
                        <el-table-column label="图片" width="100" prop="scenicPhoto" v-if="item.typeId == 5">
                            <template slot-scope="scope">
                                <el-popover placement="top-start" title="" trigger="hover">
                                    <img :src="scope.row.scenicPhoto" alt="" style="width: 300px;" />
                                    <img
                                    slot="reference"
                                    :src="scope.row.scenicPhoto"
                                    style="width: 60px;"
                                    />
                                </el-popover>
                            </template>
                        </el-table-column>



                        <el-table-column fixed="right" label="操作" width="100">
                            <template slot-scope="scope">
                            <el-button @click="handleClick(scope.row,item.typeId)" type="text" size="small"
                                >编辑</el-button
                            >
                            <el-button @click="removeClick(scope.row,item.typeId)" type="text" size="small"
                                >删除</el-button
                            >
                            </template>
                        </el-table-column>
                    </el-table>
                    <div class="footer_dom">
                        <el-pagination background layout="total, sizes,prev, pager, next, jumper" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="params.pageNum" :page-sizes="[10, 15, 20, 30]" :page-size="params.pageSize" :total="total"></el-pagination>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="基础信息" name="second" :name="'sec'+index">
                    <el-form :model="form" label-width="120px">
                        <el-form-item label="标题">
                            <el-input v-model="form.typeTitle" style="width:400px"></el-input>
                        </el-form-item>
                        <el-form-item label="图片" v-if="item.typeId != 5 && item.typeId != 2">
                            <div class="upload_box">
                                <input class="upload_input" type="file" @change="getFile"/>
                                <el-image class="upload_img" :src="form.typePhoto" fit="contain" v-if="form.typePhoto">
                                    <div slot="error" class="image-slot">
                                        <i class="el-icon-picture-outline"></i>
                                    </div>
                                </el-image>
                                <i class="el-icon-plus upload_icon" v-if="!form.typePhoto"></i>
                            </div>
                        </el-form-item>
                        <el-form-item label="概括" v-if="item.typeId == 5 || item.typeId == 2">
                            <el-input type="textarea" v-model="form.typeSynopsis" style="width:400px"></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" @click="saveTypeInfo(item.typeId)">保存</el-button>
                        </el-form-item>
                    </el-form>
                </el-tab-pane>
            </el-tabs>
        </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
export default {
    props:['typeList'],
    computed:{
        // filtrTypeList(){
        //     console.log(33)
        //     let arr = this.typeList.filter(val=>{return  val.typeId == 3 || val.typeId == 4 || val.typeId == 5})
        //     if(arr.length > 0){
        //         if(Object.keys(this.form).length == 0){
        //             this.form = JSON.parse(JSON.stringify(arr[0])) 
        //         }
        //     }
        //     return arr
        // }
    },
  data() {
    return {
        activeName:'first0',
        form:{},
        list: [],
        params:{
            pageNum: 1,
            pageSize:10,
        },
        total:0,
        xlsxFile:null,
        listUrl:'/party/queryPartyList'
    };
  },
  mounted() {
    if(this.typeList.length > 0){
        this.form = JSON.parse(JSON.stringify(this.typeList[0]))
        this.listUrl = {'2':'/image/queryImageListBySmall','3':'/party/queryPartyList','4':'/news/queryNewsList','5':'/scenic/queryScenicList'}[this.form.typeId]
        this.getParty();
    }else{
        this.form = {}
    }
    this.activeName = 'first0';
    this.params.pageNum=1
    
  },
  methods: {
    saveTypeInfo(id){
        let that = this
        let formData = new FormData();
        formData.append("file", this.xlsxFile ? this.xlsxFile: null);
        formData.append("typeId",  this.form.typeId);
        formData.append("typeTitle",  this.form.typeTitle);
        formData.append("typeSynopsis",  this.form.typeSynopsis);
        // let plateType={
        //         typeId:this.form.typeId,
        //         typeTitle:this.form.typeTitle,
        //         typeSynopsis:this.form.typeSynopsis
        //     }
        //     formData.append("plateType", plateType);
        // let formData = {
        //     file:this.xlsxFile,
        //     plateType:{
        //         typeId:this.form.typeId,
        //         typeTitle:this.form.typeTitle,
        //         typeSynopsis:this.form.typeSynopsis
        //     }
        // }
        that.axios.post("/plateType/update", formData,{headers: { "Content-Type": "application/x-www-form-urlencoded" }}).then((res) => {
            if(res.data.code == 200){
                alert ('修改成功')
                this.$emit("getTypeList")
            }
        }).catch((res) => {});
    },
    ToBreak(val) {
      let strArray = []
      let str = '-'
      if (val !== undefined) {
        strArray = val.split(/(.{50})/).filter(s => s)
        str = strArray.join('<br />')
      }
      return str
    },
    getFile(e) {
      //获取file内容
      let files = e.target.files[0];
      console.log(files)
      this.xlsxFile = files;
      this.form.typePhoto = this.getObjectURL(files)
    },
    //建立一個可存取到該file的url
    getObjectURL(file){
        let url = null
        // 下面函数执行的效果是一样的，只是需要针对不同的浏览器执行不同的 js 函数而已
        if (window.createObjectURL != undefined) {
            // basic
            url = window.createObjectURL(file)
        } else if (window.URL != undefined) {
            // mozilla(firefox)
            url = window.URL.createObjectURL(file)
        } else if (window.webkitURL != undefined) {
            // webkit or chrome
            url = window.webkitURL.createObjectURL(file)
        }
        return url
    },
    changeTypeCli(e){
        this.list = []
        this.form = JSON.parse(JSON.stringify(this.typeList[e.paneName])) 
        if(this.typeList[e.paneName].typeId != 6){
            this.activeName = 'first'+e.paneName
            this.listUrl = {'2':'/image/queryImageListBySmall','3':'/party/queryPartyList','4':'/news/queryNewsList','5':'/scenic/queryScenicList'}[this.typeList[e.paneName].typeId]
            this.params.pageNum=1
            this.getParty();
        }else{
            this.activeName = 'sec'+e.paneName
        }
    },
    handleClick(row,id) {
        if(id == 2){
            this.$router.push({ name: "ImageChange", query: {id:row.imageId} });
        }else if(id == 3){
            this.$router.push({ name: "PartyChange", query: { id:row.partyId } });
        }else if(id == 4){
            this.$router.push({ name: "NewsChange", query: { id:row.newsId } });
        }else if(id == 5){
            this.$router.push({ name: "StrollChange", query: { id:row.scenicId } });
        }
    },
    removeClick(row,typeId) {
        let id = null
        let str = null
        if(typeId == 2){
            id = row.imageId
            str = '/image/deleteImageById'
        }else if(typeId == 3){
            id = row.partyId
            str = '/party/deleteParty'
        }else if(typeId == 4){
            id = row.newsId
            str = '/news/deleteNews'
        }else if(typeId == 5){
            id = row.scenicId
            str = '/scenic/deleteScenic'
        }
      // console.log(row.partyId);
      let result = confirm(
        `是否删除ID为${id}的数据，如果删除，无法恢复数据！`
      );
      if (result) {
        this.axios
          .delete(`${str}?id=${id}`)
          .then((res) => {
            if (res.data.code == 200) {
              alert(`成功删除ID为${row.partyId}的数据`);
              this.params.pageNum = 1
                this.getParty()
            }
          });
      }
    },
    addCli(typeId) {
        let url = null
        let query = {}
        if(typeId == 2){
            url = '/pageAdmin/addimage'
            query.type = 1
        }else if(typeId == 3){
            url = '/pageAdmin/partyAdd'
        }else if(typeId == 4){
            url = '/pageAdmin/newsAdd'
        }else if(typeId == 5){
            url = '/pageAdmin/strollAdd'
        }
      this.$router.push({path:url,query});
    },
    //分页
    getParty() {
      let that = this;
      that.axios
        .get(this.listUrl,{params:this.params})
        .then((res) => {
            this.total = res.data.data.total
            this.list = res.data.data.list.map(val=>{
                if(val.partyPhoto){
                    val.partyPhoto = this.$store.state.imgUrl + "/images/" + val.partyPhoto;
                }else if(val.newsPhoto){
                    val.newsPhoto = this.$store.state.imgUrl + "/images/" + val.newsPhoto;
                }else if(val.scenicPhoto){
                    val.scenicPhoto = this.$store.state.imgUrl + "/images/" + val.scenicPhoto;
                }else if(val.imagePath){
                    val.imagePath = this.$store.state.imgUrl + "/images/" + val.imagePath;
                }
                
                return val
            })
        //   const { list, pageNum, pageSize, navigatepageNums } = res.data.data;
        //   list.forEach((element) => {
        //     element.partyPhoto =
        //       this.$store.state.imgUrl + "/images/" + element.partyPhoto;
        //   });
        //   that.list = list;
        //   that.data = res.data.data;
          // console.log(list)
          // console.log(that.data)
        });
    },
    handleSizeChange(val){
        this.params.pageSize = val
        this.getParty()
    },
    handleCurrentChange(val){
        this.params.pageNum = val
        this.getParty()
    },
  },
  watch:{
    typeList(){
        if(this.typeList.length > 0){
            if(Object.keys(this.form).length == 0){
                this.form = JSON.parse(JSON.stringify(this.typeList[0]))
                this.listUrl = {'2':'/image/queryImageListBySmall','3':'/party/queryPartyList','4':'/news/queryNewsList','5':'/scenic/queryScenicList'}[this.form.typeId]
                this.getParty();
                this.activeName = 'first0'
            }
        }else{
            this.form = {}
            this.list = []
            this.total = 0
        }
    },
  }
};
</script>
<style scoped>
.pagess {
  padding: 35px;
  width: 1000px;
}
.pagess ul {
  margin: 0 auto;
  width: 50%;
}
.pagess ul li {
  margin-left: 1%;
  float: left;
  padding: 6px 3%;
  cursor: pointer;
  border: 1px solid #e8e8e8;
  font-size: 0.8rem;
}
.pagess ul li:hover {
  border: 1px solid #666;
  color: rgb(34, 30, 30);
}
.active {
  background: #e8e8e8;
}
.jump {
  line-height: 33px;
  float: left;
}
.jump input {
  border: 1px solid #e8e8e8;
  color: rgb(34, 30, 30);
  width: 40px;
  height: 33px;
  text-align: center;
}
.search {
  border: 1px solid #e8e8e8;
  color: rgb(34, 30, 30);
  height: 33px;
  text-align: center;
  padding: 10px;
  margin-left: 10px;
}
.search:hover {
  /* border:1px solid #666; */
  color: rgb(34, 30, 30);
  background: #e8e8e8;
  cursor: pointer;
}
.footer_dom{
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-top: 25px;
}

.el-menu-demo{
    background-color:#efeeee;
    border-top: 1px solid #efeeee;
    border-left: 1px solid #efeeee;
}
.upload_box{
    position:relative;
    height:140px;
}
.upload_input{
    position:absolute;
    width:140px;
    height:140px;
    opacity:0;
    z-index:1;
}
.upload_img{
    width: 140px;
    height: 140px;
    position:absolute;
    left:0;
    top:0;
    border:1px solid #ddd;
    border-radius:8px;
    overflow:hidden;
}
.upload_icon{
    position:absolute;
    left:0;
    top:0;
    width:140px;
    height:140px;
    border:1px solid #ddd;
    border-radius:8px;
    font-size:32px;
    line-height:140px;
    text-align:center;
}
.oneLine {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
