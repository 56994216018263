export function imgToFile(url, cb) {
    let that = this;
    var image = new Image();
    image.crossOrigin = "";
    image.src = url;
    image.onerror = (e)=>{
        cb(null)
    }
    image.onload =  (e)=>{
        console.log(11,e)
        var canvas = document.createElement("canvas");
            canvas.width = image.width;
            canvas.height = image.height;
        var ctx = canvas.getContext("2d");
            ctx.drawImage(image, 0, 0, image.width, image.height);
        var ext = image.src.substring(image.src.lastIndexOf(".") + 1).toLowerCase();
        let base64 = canvas.toDataURL("image/jpeg" + ext); //图片转base64


        let arr = base64.split(',');
            let mime = arr[0].match(/:(.*?);/)[1];
            let bytes = atob(arr[1]); // 解码base64
            let n = bytes.length
            let ia = new Uint8Array(n);
            while (n--) {
                ia[n] = bytes.charCodeAt(n);
            }
            let file =  new File([ia], "file", { type: mime });

        // 根据自身需求调整【因个人项目逻辑不一样，这里使用回调函数】
        cb && typeof cb == "function" && cb(file);
        // return file;
    };
}