<template>
  <div v-if="this.list">
    <el-card class="box-card">
        <el-form :model="list" label-width="100px" :rules="rules" ref="ruleForm">
            <el-form-item label="标题" prop="partyTitle">
                <el-input v-model="list.partyTitle" style="width:400px"></el-input>
            </el-form-item>
            <el-form-item label="时间" prop="partyAddTime">
                <el-date-picker v-model="list.partyAddTime" type="datetime" placeholder="选择日期" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
            </el-form-item>
            <el-form-item label="图片" prop="xlsxFile">
                <div class="upload_box">
                    <input class="upload_input" type="file" @change="getFile"/>
                    <el-image class="upload_img" :src="list.partyPhoto" fit="contain" v-if="list.partyPhoto">
                        <div slot="error" class="image-slot">
                            <i class="el-icon-picture-outline"></i>
                        </div>
                    </el-image>
                    <i class="el-icon-plus upload_icon" v-if="!list.partyPhoto"></i>
                </div>
            </el-form-item>
            <el-form-item label="概括" prop="partySynopsis">
                <el-input type="textarea" :rows="3" v-model="list.partySynopsis" style="width:400px"></el-input>
            </el-form-item>
            <el-form-item label="内容" prop="partySynopsis">
                <quill @quill = "quillVal" :Pquillval="list.partyContext"></quill>
            </el-form-item>
            <el-form-item>
                <div class="d_flex jc_end">
                    <el-button type="primary" @click="importRow" style="margin-top:50px">确认修改</el-button>
                </div>
            </el-form-item>
        </el-form>
    </el-card>
  </div>
</template>
<script>
import Quill from './Quill.vue';
import {imgToFile} from '@/utils/index'
export default {
  data() {
    return {
        list: null,
        rules:{
            partyTitle:{ required: true, message: '标题不能为空', trigger: 'blur' },
            partyAddTime:{required: true, message: '时间不能为空', trigger: 'blur'},
            xlsxFile:{ required: true, message: '图片不能为空', trigger: 'blur' },
            partySynopsis:{ required: true, message: '概括不能为空', trigger: 'blur' },
            partyContext:{ required: true, message: '内容不能为空', trigger: 'blur' }
        }
    };
  },
  mounted() {
    let id = this.$route.query.id
    if(id === null || id === undefined || id === ''){
        history.go(-1)
    }else{
        this.gets(id);
    }
  },
  components:{
    Quill
  },
  methods: {
    gets(id){
      // console.log(a)
      this.axios.get('/party/queryPartyById',{params:{id}})
      .then(res => {
        // console.log(res)
        let url = this.$store.state.imgUrl + "/images/" + res.data.data.partyPhoto
        imgToFile(url,file=>{
            res.data.data.xlsxFile = file
            res.data.data.partyPhoto = url
            this.list=res.data.data
        })
        
      })
      .catch(err => {
        console.error(err); 
      })
    },
    quillVal(data){
      console.log(222,data)
      this.list.partyContext = data.html
    },
    getFile(e) {
        //获取file内容
        let files = e.target.files[0];
        // console.log(this.$refs.fileId.files[0])
        this.list.xlsxFile = files;
        this.list.partyPhoto = this.getObjectURL(files);
    },
    //建立一個可存取到該file的url
    getObjectURL(file){
        let url = null
        // 下面函数执行的效果是一样的，只是需要针对不同的浏览器执行不同的 js 函数而已
        if (window.createObjectURL != undefined) {
            // basic
            url = window.createObjectURL(file)
        } else if (window.URL != undefined) {
            // mozilla(firefox)
            url = window.URL.createObjectURL(file)
        } else if (window.webkitURL != undefined) {
            // webkit or chrome
            url = window.webkitURL.createObjectURL(file)
        }
        return url
    },
    //上传文件
    importRow() { 
        let that = this;
        this.$refs.ruleForm.validate((valid) => {
            if (valid) {
                let formData = new FormData();
                formData.append("file", this.list.xlsxFile ? this.list.xlsxFile: null);
                formData.append("partyContext",  this.list.partyContext);
                formData.append("partyPath",  this.list.partyPath);
                formData.append("partyTitle",  this.list.partyTitle);
                formData.append("partyId",  this.list.partyId);
                formData.append("partySynopsis",  this.list.partySynopsis);
                formData.append("partyAddTime",  this.list.partyAddTime);
                formData.append("partyTop",  0);
                that.axios.post("/party/updateParty", formData, {headers: { "Content-Type": "application/x-www-form-urlencoded" }}).then((res) => {
                    if(res.data.code == 200){
                        alert ('修改成功')
                        history.go(-1)
                    }
                }).catch((res) => {});
            }
        });
    },
  },
};
</script>
<style scoped>
.upload_box{
    position:relative;
    height:140px;
}
.upload_input{
    position:absolute;
    width:140px;
    height:140px;
    opacity:0;
    z-index:1;
}
.upload_img{
    width: 140px;
    height: 140px;
    position:absolute;
    left:0;
    top:0;
    border:1px solid #ddd;
    border-radius:8px;
    overflow:hidden;
}
.upload_icon{
    position:absolute;
    left:0;
    top:0;
    width:140px;
    height:140px;
    border:1px solid #ddd;
    border-radius:8px;
    font-size:32px;
    line-height:140px;
    text-align:center;
}
</style>
