<template>
<el-card class="box-card">
    <el-table :data="list" :header-cell-style="{textAlign:'center'}" stripe tooltip-effect="light" style="width: 100%">
        <el-table-column prop="typeId" label="分类id" width="120" align="center">
        </el-table-column>
        <el-table-column prop="typeTitle" label="标题" show-overflow-tooltip width="160">
        </el-table-column>
        <el-table-column prop="typeSynopsis" label="简介" show-overflow-tooltip min-width="200"> </el-table-column>
        <el-table-column label="图片" width="100">
            <template slot-scope="scope">
            <el-popover placement="top-start" title="" trigger="hover">
                <img :src="scope.row.typePhoto" alt="" style="width: 300px;" />
                <img
                slot="reference"
                :src="scope.row.typePhoto"
                style="width: 60px;"
                />
            </el-popover>
            </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="100">
            <template slot-scope="scope">
            <el-button @click="handleClick(scope.row)" type="text" size="small"
                >编辑</el-button
            >
            </template>
        </el-table-column>
    </el-table>
  
</el-card>

</template>
<script>
export default {
  data() {
    return {
      list: []
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      this.axios.get('/plateType/queryPlateTypeList',{params:{pageNum:1,pageSize:20}}).then(res=>{
        this.list = res.data.data.list.map(val=>{
            val.typePhoto = val.typePhoto ? this.$store.state.imgUrl + "/images/" + val.typePhoto : null;
            return val
        })
        console.log("分类表",res);
      }).catch(err=>{
        console.log('报错')
      })
    },
  },
};
</script>
<style scoped>
.footer_dom{
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-top: 14px;
}
</style>
