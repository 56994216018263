<template>
    <van-sticky style="z-index:1000">
        <div class="header_h5">
            <van-nav-bar :title="headerText" :left-text="leftText" style="color:#f00" right-text="菜单" :left-arrow="showIcon" @click-left="back" @click-right="onClick"/>
            <!-- <img src="../assets/image/logo.png" style="position:absolute;left:0;top:0;height:45px;width:auto;z-index:1" @click="urlTo('/')" alt="白洋淀风景区" />
            <van-button type="primary" style="position: absolute; right: 0; top: 0; z-index: 100" @click="propShow = true">菜单</van-button> -->
            <van-popup :close-on-click-overlay="false" @click-overlay="closeProp" :value="propShow" position="right" :style="{ height: '100%' }">
                <div class="prop_box">
                    <img src="../assets/image/logo.jpg" style="width:80px;height:auto;margin-bottom:40px" alt="">
                    <ul class="prop_ul">
                        <li v-for="(item,index) in typeList" :class="activeUrl == item.typePath ? 'act' : ''" @click="urlTo(item.typePath)" :key="index">{{item.typeTitle}}</li>
                    </ul>
                </div>
            </van-popup>
        </div>
    </van-sticky>
</template>
<script>
export default {
    props:['typeList'],
    data() {
        return {
            leftText:'',
            showIcon:false,
            text: "游进白洋淀",
            // propShow: false,
            // 通过 actions 属性来定义菜单选项
            // actions: [
            //     { text: "游进白洋淀",url:'/' },
            //     { text: "游玩白洋淀",url:'/playList' },
            //     { text: "白洋淀党建",url:'/list' },
            //     { text: "白洋淀资讯",url:'/lists' },
            //     { text: "白洋淀游客",url:'/ticket' },
            // ],
            activeUrl:'/'
        };
    },
    computed:{
        headerText(){
            return this.$store.state.headerText
        },
        propShow(){
            return this.$store.state.propShow
        },
    },
    methods: {
        closeProp(){
            this.$store.commit("SET_PROP_SHOW",false)
        },
        onClick(){
            this.$store.commit("SET_PROP_SHOW",true)
            // this.propShow = true
        },
        back(){
            if(this.activeUrl != '/'){
                this.$router.go(-1)
            }
        },
        urlTo(url){
            if(this.activeUrl != url){
                this.$router.push(url)
            }
        },
    },
    watch:{
        $route(){
            console.log("路由",this.$route.fullPath)
            this.activeUrl = this.$route.fullPath
            this.$store.commit("SET_PROP_SHOW",false)
            // this.propShow = false
            if(this.activeUrl != '/'){
                this.leftText = '返回'
                this.showIcon = true
            }else{
                this.leftText = ''
                this.showIcon = false
            }
            let obj = this.typeList.find(val=>{return val.typePath == this.$route.fullPath})
            if(obj !== undefined){
                this.$store.commit("SET_HEADER_TEXT",obj.typeTitle)
                // this.$store.commit("SET_SHOW_FOOTER",true)
            }else{
                // this.$store.commit("SET_SHOW_FOOTER",false)
            }
        },
        propShow(){}
    }
};
</script>
<style scoped lang="less">
@import "../assets/css/index_response.css";
.header_h5{
    .prop_box{
        display: flex;
        flex-direction: column;
        align-items: center;
        .prop_ul{
            height: 100%;
            display: flex;
            flex-direction: column;
            li{
                font-size: 16px;
                padding:10px 20px;
                &.act{
                    background-color: green;
                    color: #FFFFFF;
                }
            }
        }
    }
    /deep/ .van-nav-bar__text{
        color:green
    }
    /deep/ .van-nav-bar .van-icon{
        color:green
    }
}
</style>