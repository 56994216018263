<template>
  <div v-if="this.data">
    <div id="contain_h5">
        <img src="../assets/image/byd3.jpg" />
        <div class="contain_h5_card">
            <h3 class="contain_h5_card_title">{{boxText}}</h3>
            <div class="card_list">
                <div class="card_cell" v-for="(item, index) in data.list" :key="index" @click="urlTo(item)">
                    <img :src="item.newsPhoto" style="width:100%;height:auto" />
                    <div class="card_text">
                        <h4 class="t_ellipsis">{{item.newsTitle}}</h4>
                        <div class="t_ellipsis_4" v-html="item.newsSynopsis"></div>
                    </div>
                </div>
            </div>
        </div>
        <div style="padding:20px 0">
            <van-pagination v-model="pageNum" :total-items="total" :items-per-page="8" @change="search" force-ellipses />
        </div>
    </div>
    <div id="contain">
      <div class="banner">
        <img src="../assets/image/byd3.jpg" />
      </div>

      <div class="pop">
        <div class="nav_s">
          <div class="title">
            <div class="title_pop">
              <span>{{boxText}}</span>
            </div>
          </div>
          <ul>
            <li><a href="#/">首页</a></li>
            <li>></li>
            <li><a href="javascript:;">{{boxText}}</a></li>
          </ul>
        </div>
        <p style="margin-top:50px;font-size:2rem">近期活动</p>
        <div class="wrap" v-for="(item, index) in data.list" :key="index">
          <img :src="item.newsPhoto" />
          <div class="wrap_content">
            <h2>{{ item.newsTitle }}</h2>
            <br />
            <span v-html="item.newsSynopsis">{{ item.newsSynopsis }}</span
            ><br />
            <!-- <span style="margin:13px 0;display:block">{{
              item.newsAddTime
            }}</span> -->

            <div class="loadmore_pop">
              <router-link
                :to="{ name: 'Detailsy', query: { ID: item.newsId } }"
                ><h3 class="loadmore">加载更多</h3></router-link
              >
              <span></span>
            </div>
          </div>
          <div class="clear"></div>
        </div>
        <div class="pagess">
          <ul>
            <li @click="upList(1)">首页</li>
            <li v-show="!data.isFirstPage" @click="minuspage">上一页</li>
            <li
              :class="{ active: data.pageNum == item }"
              v-for="(item, index) in data.navigatepageNums.length"
              :key="index"
              @click="upList(item, index)"
            >
              {{ item }}
            </li>
            <li v-show="!data.isLastPage" @click="addpage">下一页</li>
          </ul>
          <div class="jump">
            <span>跳转到第</span><input type="text" v-model="pageNum" /><span
              >页</span
            >
            <button class="search" @click="search">搜索</button>
          </div>
          <div class="clear"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
    props:['typeList'],
  data() {
    return {
      data: null,
      pageNum: 1,
      total:0
    };
  },
  computed:{
    boxText(){
        let obj = this.typeList.find(val=>{return val.typeId == 4})
        if(obj !== undefined){
            return obj.typeTitle
        }else{
            return '-'
        }
    },
  },
  mounted() {
    this.getnews(1);
  },
  methods: {
    urlTo(item){
        this.$router.push({
            path: '/Detailsy',
            query: { ID: item.newsId }
        })
    },
    getnews(Num) {
      let that = this;
      that.axios
        .get(`/news/queryNewsList?pageNum=${Num}&pageSize=8`)
        .then((res) => {
          // console.log(res);
          const { list, pageNum, pageSize, navigatepageNums,total } = res.data.data;
          this.total = total
          this.pageNum = pageNum
          list.forEach((element) => {
            element.newsPhoto =
              this.$store.state.imgUrl + "/images/" + element.newsPhoto;
          });
          that.data = res.data.data;
        });
    },
    upList(Num) {
      let that = this;
      let data = that.data;
      // console.log(data)
      that.getnews(Num);
    },
    minuspage() {
      let that = this;
      let data = that.data;
      if (data.isFirstPage) {
        return;
      }
      that.upList(that.pageNum - 1);
    },
    addpage() {
      let that = this;
      let data = that.data;
      if (data.isLastPage) {
        return;
      }
      that.upList(that.pageNum + 1);
    },
    search() {
      this.upList(this.pageNum);
    },
  },
};
</script>
<style scoped lang="less">
* {
  margin: 0;
  padding: 0;
}
.banner {
  width: 100%;
}
.title {
  width: 27%;
  height: 90px;
  background: rgba(0, 0, 0, 0.8);
  position: absolute;
  bottom: 77%;
  padding: 10px;
}
.title .title_pop {
  border: 2px solid #fff;
}
.title .title_pop span {
  color: #fff;
  font-size: 2rem;
  line-height: 66px;
}
.pop {
  /* width: 1400px; */
  margin: 0 auto;
  position: relative;
  box-sizing: border-box;
  padding-left: 10%;
}
.pop ul > li {
  float: left;
  margin: 0 1%;
}
.pop .nav_s {
  padding: 50px 0;
  border-bottom: 1px #ddd solid;
  position: relative;
}
.wrap {
  padding: 50px 0;
  box-sizing: border-box;
  border-bottom: 1px #ddd solid;
  display: flex;
}
.wrap img {
  width: 400px;
  height: 250px;
}
.wrap .wrap_content {
  margin-left: 2%;
  width: 65%;
}
.clear {
  clear: both;
}
.loadmore_pop {
  overflow: hidden;
  position: relative;
  width: 77px;
  margin-top: 20px;
}
.loadmore_pop span {
  width: 100%;
  height: 2px;
  position: absolute;
  background: rgb(240, 165, 5);
  bottom: 0;
  left: -77px;
  transition: left 1s linear;
  -moz-transition: left 1s;
  -webkit-transition: left 1s;
  -o-transition: left 1s;
}
.loadmore_pop:hover span {
  left: 0;
}
.loadmore {
  border-bottom: 2px solid #000;
  padding: 5px;
  cursor: pointer;
}
.pagess {
  padding: 35px;
  width: 100%;
}
.pagess ul {
  margin-left: 40%;
  width: 50%;
}
.pagess ul li {
  padding: 6px 2%;
  cursor: pointer;
  border: 1px solid #e8e8e8;
  font-size: 0.8rem;
}
.pagess ul li:hover {
  border: 1px solid #666;
  color: rgb(34, 30, 30);
}
.active {
  background: #e8e8e8;
}
.jump {
  line-height: 33px;
  float: left;
}
.jump input {
  border: 1px solid #e8e8e8;
  color: rgb(34, 30, 30);
  width: 40px;
  height: 33px;
  text-align: center;
}
.search {
  border: 1px solid #e8e8e8;
  color: rgb(34, 30, 30);
  height: 33px;
  text-align: center;
  padding: 10px;
  margin-left: 10px;
}
.search:hover {
  /* border:1px solid #666; */
  color: rgb(34, 30, 30);
  background: #e8e8e8;
  cursor: pointer;
}









@media (min-width: 751px) {
    #contain_h5{
        display: none;
    }
}
@media (max-width: 750px) {
    #contain{
        display: none;
    }
}
#contain_h5{
    background-color: #F2F2F2;
    .contain_h5_card{
        .contain_h5_card_title{
            text-align: center;
            font-size: 18px;
            margin: 20px 0;
        }
        .card_list{
            padding: 0 10px;
            .card_cell{
                background-color: #FFFFFF;
                border-radius: 8px;
                overflow: hidden;
                box-shadow: 0 0 2px grey;
                &+.card_cell{
                    margin-top: 8px;
                }
                .card_text{
                    padding: 10px;
                }
            }
        }
    }
}
</style>
