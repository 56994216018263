<template>
    <div id="header" class="bak">
      <div class="wrap" style="height: 65px;">
        <div class="logo">
          <a href="#/">
            <img src="../assets/image/logo.png" alt="白洋淀风景区" style="height:100%;width:auto"/>
          </a>
        </div>
        <div class="nav">
          <div class="nav">
            <ul class="clear">
                <li class="title_class" v-for="(item,index) in typeList" :key="index" @click="urlTo(item.typePath)">
                    <span>
                        <img style="width:15px" :src="item.typeIcon" alt="游进白洋淀" />
                        <span>{{item.typeTitle}}</span>
                    </span>
                </li>
              <!-- <li>
                <span
                  ><img src="../assets/image/ywbyd.png" alt="游玩白洋淀" /><a
                    href="#/playList"
                    >游玩白洋淀</a
                  ></span
                >
              </li>
              <li>
                <span
                  ><img src="../assets/image/byddj.png" alt="白洋淀党建" /><a
                    href="#/list"
                    >白洋淀党建</a
                  ></span
                >
              </li>
              <li>
                <span
                  ><img
                    style="width:18px"
                    src="../assets/image/bydzx.png"
                    alt="白洋淀资讯"
                  /><a href="#/lists">白洋淀资讯</a></span
                >
              </li>
              <li>
                <span
                  ><img
                    style="width:15px"
                    src="../assets/image/bydyk.png"
                    alt="白洋淀游客"
                  /><a href="#/ticket">我要购票</a></span>
              </li> -->
            </ul>
          </div>
        </div>
        <div class="Language">
        </div>
        <div class="tools clear"></div>
      </div>
    </div>
</template>
<script>
export default {
    props:['typeList'],
    data() {
        return {
            
        };
    },
    computed:{
        
    },
    mounted(){
        // this.$store.commit("SET_SHOW_FOOTER",true)
    },
    methods: {
        urlTo(url){
            let arr = window.location.hash.split(url)
            if(window.location.hash.indexOf(url) == -1 || (arr.length > 1 && arr[1] !== '')){
                this.$router.push(url)
            }
        }
    },
    watch:{
        
    }
};
</script>
<style scoped>
@import '../assets/css/index_response.css';
.title_class{
    color: #FFF;
}
.title_class:hover{
    color: green;
}
</style>