<template>
  <div v-if="this.list" class="ql-snow">
    <el-card class="box-card">
        <el-form :model="list" label-width="100px" :rules="rules" ref="ruleForm">
            <el-form-item label="标题" prop="newsTitle">
                <el-input v-model="list.newsTitle" style="width:400px"></el-input>
            </el-form-item>
            <el-form-item label="时间" prop="newsAddTime">
                <el-date-picker v-model="list.newsAddTime" type="datetime" placeholder="选择日期" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
            </el-form-item>
            <el-form-item label="图片" prop="xlsxFile">
                <div class="upload_box">
                    <input class="upload_input" type="file" @change="getFile"/>
                    <el-image class="upload_img" :src="list.newsPhoto" fit="contain" v-if="list.newsPhoto">
                        <div slot="error" class="image-slot">
                            <i class="el-icon-picture-outline"></i>
                        </div>
                    </el-image>
                    <i class="el-icon-plus upload_icon" v-if="!list.newsPhoto"></i>
                </div>
            </el-form-item>
            <el-form-item label="概括" prop="newsSynopsis">
                <el-input type="textarea" :rows="3" v-model="list.newsSynopsis"></el-input>
            </el-form-item>
            <el-form-item label="内容" prop="newsContext">
                <quill @quill = "quillVal" :Pquillval="list.newsContext"></quill>
            </el-form-item>
            <el-form-item>
                <div class="d_flex jc_end">
                    <el-button type="primary" @click="importRow" style="margin-top:50px">确认修改</el-button>
                </div>
            </el-form-item>
        </el-form>
    </el-card>
  </div>
</template>
<script>
import Quill from './Quill.vue';
import {imgToFile} from '@/utils/index'
export default {
  data() {
    return {
        list: null,
        rules:{
            newsTitle:{ required: true, message: '标题不能为空', trigger: 'blur' },
            newsAddTime:{required: true, message: '时间不能为空', trigger: 'blur'},
            xlsxFile:{ required: true, message: '图片不能为空', trigger: 'blur' },
            newsSynopsis:{ required: true, message: '概括不能为空', trigger: 'blur' },
            newsContext:{ required: true, message: '内容不能为空', trigger: 'blur' }
        }
    };
  },
  mounted() {
    let id = this.$route.query.id
    if(id === null || id === undefined || id === ''){
        history.go(-1)
    }else{
        this.gets(id);
    }
  },
  components:{
    Quill
  },
  methods: {
    gets(id){
        let that = this
      this.axios.get('/news/queryNewsById',{params:{id}})
        .then(res => {
            let url = this.$store.state.imgUrl + "/images/" + res.data.data.newsPhoto
            imgToFile(url,file=>{
                res.data.data.xlsxFile = file
                res.data.data.newsPhoto = url
                this.list=res.data.data
            })
        })
      .catch(err => {
        console.error(333,err); 
      })
    },
    quillVal(data){
      // console.log(data)
      this.list.newsContext = data.html
    },
    getFile(e) {
        //获取file内容
        let files = e.target.files[0];;
        // console.log(this.$refs.fileId.files[0])
        this.list.xlsxFile = files;
        this.list.newsPhoto = this.getObjectURL(files);
    },
    //建立一個可存取到該file的url
    getObjectURL(file){
        let url = null
        // 下面函数执行的效果是一样的，只是需要针对不同的浏览器执行不同的 js 函数而已
        if (window.createObjectURL != undefined) {
            // basic
            url = window.createObjectURL(file)
        } else if (window.URL != undefined) {
            // mozilla(firefox)
            url = window.URL.createObjectURL(file)
        } else if (window.webkitURL != undefined) {
            // webkit or chrome
            url = window.webkitURL.createObjectURL(file)
        }
        return url
    },
    //上传文件
    importRow() {
        let that = this;
        this.$refs.ruleForm.validate((valid) => {
            if (valid) {
                let formData = new FormData();
                formData.append("file", that.list.xlsxFile ? that.list.xlsxFile: null);
                formData.append("newsContext",  this.list.newsContext);
                formData.append("newsPath",  this.list.newsPath);
                formData.append("newsTitle",  this.list.newsTitle);
                formData.append("newsId",  this.list.newsId);
                formData.append("newsSynopsis",  this.list.newsSynopsis);
                formData.append("newsAddTime",  this.list.newsAddTime);
                
                formData.append("newsTop",  0);
                that.axios.put("/news/updateNews", formData, {headers: { "Content-Type": "application/x-www-form-urlencoded" }}).then((res) => {
                    if(res.data.code == 200){
                        alert ('修改成功')
                        history.go(-1)
                    }
                }).catch((res) => {});
            }
        });
    },
  },
};
</script>
<style scoped>
.upload_box{
    position:relative;
    height:140px;
}
.upload_input{
    position:absolute;
    width:140px;
    height:140px;
    opacity:0;
    z-index:1;
}
.upload_img{
    width: 140px;
    height: 140px;
    position:absolute;
    left:0;
    top:0;
    border:1px solid #ddd;
    border-radius:8px;
    overflow:hidden;
}
.upload_icon{
    position:absolute;
    left:0;
    top:0;
    width:140px;
    height:140px;
    border:1px solid #ddd;
    border-radius:8px;
    font-size:32px;
    line-height:140px;
    text-align:center;
}
</style>
