<template>
  <div id="culture">
      <div style="height:66px;"></div>
      <div>
          <div class="wid">
              <img src="../assets/image/culture.1.png" alt="">
          </div>
          <div>
              白洋淀文化苑是集优美生态文化、悠久历史文化、革命传统文化、淳朴民俗文化为一体的大型旅游景区。共有八大亮点：荷花淀、世界名荷园、太空荷园、白洋淀雁翎队纪念馆、嘎子村、东堤烟柳、康熙水围行宫、敕赐沛恩寺。形成了白洋淀文化苑这道亮丽的风景线，是红色旅游和绿色旅游完美结合的综合景区。
世界名荷园：集天下名荷668种和白洋淀水生植物争奇斗艳，异彩纷呈，徜徉园中，览遍天下名荷。
          </div>
      </div>
      <div>
          <div class="wid">
              <img src="../assets/image/culture.2.png" alt="">
          </div>
          <div>白洋淀雁翎队纪念馆：是由时任冀中军区司令员的开国上将吕正操将军亲笔题写的。馆内16个展厅，以大量珍贵的历史照片、实物和文献资料，再现了水上游击战的烽火硝烟。是白洋淀红色旅游的重要载体。</div>
      </div>
      <div>
          <div class="wid">
              <img src="../assets/image/culture.3.png" alt="">
          </div>
          <div>
              梦里水乡嘎子村：村内渔家小院，错落有致。街道曲折迂回，渔家戏台、苇编表演展示。您可包一个渔家小院住上一晚，尝一尝白洋淀的杂鱼饼子，也可享受全鱼宴，或到邻家串串门，亲身体验原汁原味的白洋淀风情。
东堤烟柳：是白洋淀八景之一，晴空丽日，垂柳依依，微风吹来，柳枝飘飘，细雨空濛,如烟如梦；晨暮之时，漫步其中，柳儿拂面，使人万念俱空，放浪形骸。长堤绵亘如带，万柳覆水，依依向人。每当春雨初晴，烟丝如织，何似苏堤春晓时也。
康熙水围行宫：康熙水围行宫是康熙皇帝水围白洋淀时驻跸之处。由于白洋淀近在京畿、水面开阔、物产丰富、风光秀丽。宫殿内侧碑廊琳琳，曲折绕之，有康乾二皇御笔亲题诗赋，或写白洋淀风光之秀丽，或抒水围行猎之豪烈，或写渔翁莲娃之悠然。徜徉其中，仿佛看见康熙率众臣与民同乐之情景。
敕赐沛恩寺：康熙为永保江山，巩固统治，经常是早晚两叩首，一日三柱香祈福祷告。于是便在行宫旁建造敕赐沛恩寺。寺内大殿气势雄伟，彩塑人物，神态各异、栩栩如生。整个寺院是佛学文化的瑰宝之所在。
参与项目：垂钓，赛龙舟，乘上小木船，水上撒网捕鱼，穿行在红荷绿苇中，自驾电瓶船探寻芦荡迷宫，搬鬇，观看鱼鹰捕鱼表演，亲身感受融入大自然的乐趣。如果说白洋淀是一顶华美的王冠，那么白洋淀文化苑就是王冠上璀璨的明珠。
          </div>
      </div>
  </div>
</template>

<style scoped>
@import '../assets/css/culture.css'
</style>

<script>
  export default {
    methods: {
      goBack() {
          this.$router.push('/')
        // console.log('go back');
      }
    }
  }
</script>