<template>
  <div>
    <el-card class="box-card">
        <el-form :model="videoInfo" label-width="100px" :rules="rules" ref="ruleForm">
            <el-form-item label="标题" prop="videoTitle">
                <el-input v-model="videoInfo.videoTitle" style="width:400px"></el-input>
            </el-form-item>
            <el-form-item label="视频" prop="videoPath">
                <div class="upload_box">
                    <video loop controls autoplay type="video/mp4" width="680" height="400" :src="videoInfo.videoPath" v-if="videoInfo.videoPath"></video>
                    <p>重新更新上传视频</p>
                    <div class="fileItem">
                        <input type="file" ref="fileId" @change="getFile" />
                    </div>
                </div>
            </el-form-item>
            <el-form-item label="描述" prop="videoIntro">
                <el-input type="textarea" v-model="videoInfo.videoIntro"></el-input>
            </el-form-item>
            <el-form-item>
                <div class="d_flex jc_end">
                    <el-button :icon="isupload ? '' : 'el-icon-loading'" type="primary" :disabled="!isupload" @click="importRow">{{isupload ? '确认保存' : '上传中'}}</el-button>
                </div>
            </el-form-item>
        </el-form>
    </el-card>
    
    <!-- <p>当前在线的视频</p>
    <div>
      <video
        loop
        controls
        autoplay
        type="video/mp4"
        width="680"
        height="400"
        :src="videoInfo.videoPath"
        v-if="videoInfo.videoPath"
      ></video>
    </div>
    <p>重新更新上传视频</p>
    <div class="fileItem">
      <input type="file" ref="fileId" @change="getFile" />
    </div>
    <el-button :icon="isupload ? '' : 'el-icon-loading'" type="primary" :disabled="!isupload" @click="importRow">{{isupload ? '确认保存' : '上传中'}}</el-button
    > -->
  </div>
</template>
<style scoped></style>

<script>
export default {
  data() {
    return {
        videoInfo:{},
        isupload: true,
        xlsxFile:null,
        rules:{
            videoTitle:{ required: true, message: '视频标题不能为空', trigger: 'blur' },
            videoIntro:{ required: true, message: '视频描述不能为空', trigger: 'blur' }
        }
    };
  },
  methods: {
    getvideo() {
      this.axios
        .get("/video/queryVideo")
        .then((res) => {
          // console.log(res)
          res.data.data.videoPath = this.$store.state.imgUrl + "/images/" + res.data.data.videoPath
          this.videoInfo = res.data.data
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getFile() {
      //获取file内容
      let files = this.$refs.fileId.files[0];
      // console.log(this.$refs.fileId.files[0])
      this.xlsxFile = files;
    },
    //上传文件
    importRow() {
        let that = this;
      this.$refs.ruleForm.validate((valid) => {
          if (valid) {
                this.isupload = false;
                // vue 中使用 window.FormData(),否则会报 'FormData isn't definded'
                //创建一个FormData对象，然后通过append() 方法向对象中添加键值对
                let formData = new FormData();
                formData.append("file", that.xlsxFile);
                formData.append("videoId", that.videoInfo.videoId);
                formData.append("videoTitle", that.videoInfo.videoTitle);
                formData.append("videoIntro", that.videoInfo.videoIntro);
                that.axios.post("/video/update", formData, {headers: { "Content-Type": "multipart/form-data" }}).then((res) => {
                    if (res.data.code == 200) {
                        this.isupload = true;
                        this.$message({
                            message: '添加成功',
                            type: 'success'
                        })
                        this.getvideo();
                    }else{
                        this.isupload = true;
                    }
                }).catch((res) => {
                    this.isupload = true;
                });
            }
        });
    },
  },
  mounted() {
    this.getvideo();
  },
};
</script>
