<template>
    <div>
        <el-form ref="form" :model="form" label-width="80px">
        
            <el-form-item label="上传时间">
                <el-col :span="11">
                <el-date-picker type="date" placeholder="选择日期" v-model="form.date1" style="width: 100%;"></el-date-picker>
                </el-col>
                    <!-- <el-col class="line" :span="0">-</el-col> -->
                    <el-col :span="11">
                    <!-- <el-time-picker placeholder="选择时间" v-model="form.date2" style="width: 100%;"></el-time-picker> -->
                </el-col>
            </el-form-item>
            <el-form-item label="标题">
                <el-input v-model="form.name"></el-input>
            </el-form-item>
            <el-form-item label="内容/文本">
                <el-input type="textarea" v-model="form.desc"></el-input>
            </el-form-item>
            <el-form-item label="显示类型">
                <el-select v-model="form.region" placeholder="请选择显示类型">
                <el-option label="TOP" value="TOP"></el-option>
                <el-option label="NEW" value="NEW"></el-option>
                </el-select>
            </el-form-item>
            
            <!-- <el-form-item label="即时配送">
                <el-switch v-model="form.delivery"></el-switch>
            </el-form-item> -->
            <!-- <el-form-item label="活动性质">
                <el-checkbox-group v-model="form.type">
                <el-checkbox label="美食/餐厅线上活动" name="type"></el-checkbox>
                <el-checkbox label="地推活动" name="type"></el-checkbox>
                <el-checkbox label="线下主题活动" name="type"></el-checkbox>
                <el-checkbox label="单纯品牌曝光" name="type"></el-checkbox>
                </el-checkbox-group>
            </el-form-item> -->
            <el-form-item label="是否显示">
                <el-radio-group v-model="form.resource">
                <el-radio label="显示"></el-radio>
                <el-radio label="不显示"></el-radio>
                </el-radio-group>
            </el-form-item>
            
            <el-form-item>
                <el-button type="primary" @click="onSubmit">提交</el-button>
                <!-- <el-button>取消</el-button> -->
            </el-form-item>
        </el-form>
    </div>
</template>
<script>
  export default {
    data() {
      return {
        form: {
          name: '',//标题
          region: '',//类型
          date1: '',//时间
        //   date2: '',
        //   delivery: false,
        //   type: [],
          resource: '',//显示
          desc: ''//内容
        }
      }
    },
    methods: {
      onSubmit() {
          this.axios.post('/notice/addNotice',{
            "noticeContext": this.form.desc,
            "noticeShow": this.form.resource == '不显示' ? '1' :'0',
            "noticeShowTime": this.form.date1,
            "noticeTitle": this.form.name,
            "noticeTop": this.form.region == "TOP" ? '1' : '0'
            })
          .then(res => {
              // console.log(res)
              // console.log(this.form)
              if(res.data.code==200){
                alert('添加成功')
              this.$router.push('/pageAdmin/notice')
              }
          })
          .catch(err => {
              console.error(err); 
          })
        // console.log(this.form)
      }
    }
  }
</script>