<template>
    <div>

        <div style="width:400px;margin:300px auto">
            <el-input v-model="admin" placeholder="请输入用户名"></el-input>
            <div style="height:30px">
            </div>
            <el-input v-model="adminPassword" placeholder="请输入密码"></el-input>
            <!-- <el-input v-model="adminPassword1" placeholder="请确认密码"></el-input> -->
            <div style="height:30px">
            </div>
            <el-button type="primary" style="width:100px" @click="goback">取消</el-button>
            <el-button type="primary" style="width:100px;margin-left:50%" @click="adduser">确认</el-button>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return{
            admin:'',
            adminPassword:'',
        }
    },
    methods:{
        goback(){
            this.$router.push('/pageAdmin/user')
        },
        adduser(){
            this.axios.post('/admin/addAdmin',{
                adminAccount: this.admin,
                adminName: this.admin,
                adminPassword: this.adminPassword,
                adminType: "1"
            }).then(res=>{
                // console.log(res)
                if(res.data.code==200){
                    alert('新增成功')
                    this.$router.push('/pageAdmin/user')
                }
            })
        }
    }
}
</script>