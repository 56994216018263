<template>
  <div v-if="list">
    <el-card class="box-card">
        <el-form :model="list" label-width="100px" :rules="rules" ref="ruleForm">
            <el-form-item label="标题" prop="scenicTitle">
                <el-input v-model="list.scenicTitle" style="width:400px"></el-input>
            </el-form-item>
            <el-form-item label="状态" prop="scenicShow">
                <el-radio-group v-model="list.scenicShow">
                    <el-radio :label="1">开启</el-radio>
                    <el-radio :label="2">关闭</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="图片" prop="xlsxFile">
                <div class="upload_box">
                    <input class="upload_input" type="file" @change="getFile"/>
                    <el-image class="upload_img" :src="list.scenicPhoto" fit="contain" v-if="list.scenicPhoto">
                        <div slot="error" class="image-slot">
                            <i class="el-icon-picture-outline"></i>
                        </div>
                    </el-image>
                    <i class="el-icon-plus upload_icon" v-if="!list.scenicPhoto"></i>
                </div>
            </el-form-item>
            <el-form-item label="概括" prop="scenicSynopsis">
                <el-input type="textarea" :rows="3" v-model="list.scenicSynopsis"></el-input>
            </el-form-item>
            <el-form-item label="内容" prop="scenicContext">
                <quill @quill = "quillVal" :Pquillval="list.scenicContext"></quill>
            </el-form-item>
            <el-form-item>
                <div class="d_flex jc_end">
                    <el-button type="primary" @click="importRow" style="margin-top:50px">确认修改</el-button>
                </div>
            </el-form-item>
        </el-form>
    </el-card>


    <!-- <h1>标题：</h1>
    <el-input type="textarea" :rows="5" v-model="list.scenicTitle"> </el-input>
    <h1>概括：</h1>
    <el-input type="textarea" :rows="5" v-model="list.scenicSynopsis"> </el-input>
    <h1>内容：</h1>
    <quill @quill = "quillVal" :Pquillval="list.scenicContext"></quill>
    <h1>状态：</h1>
    <label><input type="radio" name="sex" value="开启" v-model="list.scenicShow">开启</label>
    <label><input type="radio" name="sex" value="关闭" v-model="list.scenicShow">关闭</label>
    <p>当前状态：{{list.scenicShow}}</p>
    <h1>图片：</h1>
    <img :src="list.scenicPhoto" alt="" />
    <h1>如修改图片，请点击选择文件</h1>
    <div class="fileItem">
      <input type="file" ref="fileId" @change="getFile"/>
    </div>
    <el-button type="primary" @click="importRow" style="margin-top:50px">确认修改</el-button> -->
  </div>
</template>

<script>
import Quill from './Quill.vue';
import {imgToFile} from '@/utils/index'
export default {
  data() {
    return {
        list:null,
        rules:{
            scenicTitle:{ required: true, message: '标题不能为空', trigger: 'blur' },
            xlsxFile:{ required: true, message: '图片不能为空', trigger: 'blur' },
            scenicSynopsis:{ required: true, message: '概括不能为空', trigger: 'blur' },
            scenicContext:{ required: true, message: '内容不能为空', trigger: 'blur' },
            scenicShow:{required: true, message: '请选择状态类型', trigger: 'blur' }
        }
    };
  },
  mounted() {
    let id = this.$route.query.id
    if(id === null || id === undefined || id === ''){
        history.go(-1)
    }else{
        this.gets(id);
    }
  },
  components:{
    Quill
  },
  methods: {
    gets(id){
      this.axios.get('/scenic/queryScenicById',{params:{id}})
      .then(res => {
        // console.log(res)
        let url = this.$store.state.imgUrl + "/images/" + res.data.data.scenicPhoto
        imgToFile(url,file=>{
            res.data.data.xlsxFile = file
            res.data.data.scenicPhoto = url
            this.list=res.data.data
        })
        
      })
      .catch(err => {
        console.error(err); 
      })
    },
    quillVal(data){
      // console.log(data)
      this.list.scenicContext = data.html
    },
    getFile(e) {
        //获取file内容
        let files = e.target.files[0];;
        // console.log(this.$refs.fileId.files[0])
        this.list.xlsxFile = files;
        this.list.scenicPhoto = this.getObjectURL(files);
    },
    //建立一個可存取到該file的url
    getObjectURL(file){
        let url = null
        // 下面函数执行的效果是一样的，只是需要针对不同的浏览器执行不同的 js 函数而已
        if (window.createObjectURL != undefined) {
            // basic
            url = window.createObjectURL(file)
        } else if (window.URL != undefined) {
            // mozilla(firefox)
            url = window.URL.createObjectURL(file)
        } else if (window.webkitURL != undefined) {
            // webkit or chrome
            url = window.webkitURL.createObjectURL(file)
        }
        return url
    },
    //上传文件
    importRow() {
        let that = this;
        this.$refs.ruleForm.validate((valid) => {
            if (valid) {
                let formData = new FormData();
                formData.append("file", that.list.xlsxFile ? that.list.xlsxFile: null);
                formData.append("scenicContext",  this.list.scenicContext);
                formData.append("scenicPhoto",  this.list.scenicPhoto);
                formData.append("scenicTitle",  this.list.scenicTitle);
                formData.append("scenicSynopsis",  this.list.scenicSynopsis);
                formData.append("scenicId",  this.list.scenicId);
                formData.append("scenicShow",  this.list.scenicShow);
                that.axios.put("/scenic/updateScenic", formData, {headers: { "Content-Type": "application/x-www-form-urlencoded" }}).then((res) => {
                    if(res.data.code == 200){
                        alert ('修改成功')
                        history.go(-1)
                    }
                }).catch((res) => {
                    console.log(res)
                });
            }
        });
    },
  },
};
</script>
<style scoped>
.upload_box{
    position:relative;
    height:140px;
}
.upload_input{
    position:absolute;
    width:140px;
    height:140px;
    opacity:0;
    z-index:1;
}
.upload_img{
    width: 140px;
    height: 140px;
    position:absolute;
    left:0;
    top:0;
    border:1px solid #ddd;
    border-radius:8px;
    overflow:hidden;
}
.upload_icon{
    position:absolute;
    left:0;
    top:0;
    width:140px;
    height:140px;
    border:1px solid #ddd;
    border-radius:8px;
    font-size:32px;
    line-height:140px;
    text-align:center;
}
</style>