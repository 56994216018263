<template>
    <div>
        <el-card class="box-card">
            <el-button type="primary" @click="add">新增</el-button>
            <el-table  :header-cell-style="{textAlign:'center',backgroundColor:'#f5f7fa'}" stripe tooltip-effect="light":data="tableData" style="width: 100%;margin-top:25px">
                <el-table-column fixed="left" prop="noticeShowTime" label="展示日期" width="150" align="center">
                </el-table-column>
                <el-table-column prop="noticeId" label="ID" width="100">
                </el-table-column>
                <el-table-column prop="noticeTitle" label="标题" width="300">
                </el-table-column>
                <el-table-column prop="noticeContext" label="内容" width="800">
                </el-table-column>
                
                <el-table-column prop="noticeShow" label="是否显示" width="100">
                    <template slot-scope="scope">
                        <span>{{scope.row.noticeShow == 1 ? '显示' : '不显示'}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="noticeTop" label="展示的类型" width="100">
                    <template slot-scope="scope">
                        <span>{{scope.row.noticeTop == 1 ? 'TOP' : 'NEW'}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="112" fixed="right">
                    <template slot-scope="scope">
                        <el-button @click="handleClick(scope.row)" type="text" size="small">修改</el-button>
                        <el-button type="text" size="small" @click="del(scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>

        </el-card>
    
  </div>
</template>

<script>
  export default {
    methods: {
      handleClick(row) {
         this.$router.push({path:'/pageAdmin/notice_update',query:{id:row.noticeId}})
      },
      getdata(){
          this.axios.get('/notice/queryNoticeList?pageNum=1&pageSize=100')
          .then(res => {
             let tableDatas=res.data.data.list;
                this.tableData=tableDatas;
              // console.log(res)
          })
          .catch(err => {
              console.error(err); 
          })
      },
      add(){
          this.$router.push('/pageAdmin/notice_add')
      },
      del(res){
        //   console.log(res)
          this.axios.delete(`/notice/deleteNotice?id=${res.noticeId}`)
          .then(res => {
              // console.log(res)
              alert('删除成功')
              this.$router.go(0)
          })
          .catch(err => {
              console.error(err); 
          })
      }
    },
    mounted(){
        this.getdata();
    },

    data() {
      return {
        tableData: []
      }
    }
  }
</script>