<template>
  <div>
    <el-card class="box-card">
        <el-form :model="list" label-width="100px" :rules="rules" ref="ruleForm">
            <el-form-item label="标题" prop="scenicTitle">
                <el-input v-model="list.scenicTitle" style="width:400px"></el-input>
            </el-form-item>
            <el-form-item label="状态" prop="scenicShow">
                <el-radio-group v-model="list.scenicShow">
                    <el-radio :label="1">开启</el-radio>
                    <el-radio :label="2">关闭</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="图片" prop="xlsxFile">
                <div class="upload_box">
                    <input class="upload_input" type="file" @change="getFile"/>
                </div>
            </el-form-item>
            <el-form-item label="概括" prop="scenicSynopsis">
                <el-input type="textarea" :rows="3" v-model="list.scenicSynopsis"></el-input>
            </el-form-item>
            <el-form-item label="内容" prop="scenicContext">
                <quill @quill = "quillVal" :Pquillval="list.scenicContext"></quill>
            </el-form-item>
            <el-form-item>
                <div class="d_flex jc_end">
                    <el-button type="primary" @click="importRow" style="margin-top:50px">确认修改</el-button>
                </div>
            </el-form-item>
        </el-form>
    </el-card>
    <!-- <h1>（必填）要添加的游玩标题：</h1>
    <el-input type="textarea" :rows="5" v-model="strollTitle"> </el-input>
    <h1>（必填）要添加的游玩简介：</h1>
    <el-input type="textarea" :rows="5" v-model="strollSynopsis"> </el-input>
    <h1>（必填）要添加的游玩内容：</h1>
    <quill @quill = "quillVal" :Pquillval="strollContext"></quill>
    <h1>（必填）要添加的游玩显示状态：</h1>
    <el-radio v-model="strollshow" label="开放"></el-radio>
    <el-radio v-model="strollshow" label="关闭"></el-radio>
    <div style="height:30px;"></div>
    <h1>（必填）游玩信息的图片：</h1>

    <div class="fileItem">
      <input type="file" ref="fileId" @change="getFile" />
    </div>
    <el-button type="primary" @click="importRow">确认保存</el-button> -->
  </div>
</template>

<script>
import Quill from './Quill.vue';
export default {
    components:{
        Quill
    },
    data(){
        return{
            list:{
                strollSynopsis:'',
                strollTitle:'',
                strollContext:'',
                strollPath:'',
                strollshow:"",
                xlsxFile:null
            },
            rules:{
                scenicTitle:{ required: true, message: '标题不能为空', trigger: 'blur' },
                xlsxFile:{ required: true, message: '图片不能为空', trigger: 'blur' },
                scenicSynopsis:{ required: true, message: '概括不能为空', trigger: 'blur' },
                scenicContext:{ required: true, message: '内容不能为空', trigger: 'blur' },
                scenicShow:{required: true, message: '请选择状态类型', trigger: 'blur' }
            }
        }
    },
    methods:{
        // 副文本传参
        quillVal(data){
        // console.log(data)
        this.strollContext = data.html
        },
        getFile() {
        //获取file内容
        let files = this.$refs.fileId.files[0];
        // console.log(this.$refs.fileId.files[0])
        this.list.xlsxFile = files;
        },
        importRow() {
            // console.log(this.strollshow)
            let that = this;
            this.$refs.ruleForm.validate((valid) => {
                if (valid) {
                    let formData = new FormData();
                    formData.append("file", that.list.xlsxFile);
                    formData.append("strollPath",this.list.strollPath);
                    formData.append("scenicContext",  this.list.strollContext);
                    formData.append("scenicSynopsis",  this.list.strollSynopsis);
                    formData.append("scenicTitle",  this.list.strollTitle);
                    formData.append("scenicShow",  this.list.strollshow=="显示" ? '1' : '0');
                    // formData.append("strollTop",  0);
                    // that.importUrl 上传的接口url
                    // console.log(formData.get("file"));
                    that.axios
                        .post("/scenic/addScenic", formData, {
                        headers: { "Content-Type": "multipart/form-data" },
                        })
                        .then((res) => {
                        // console.log(res);
                        if(res.data.code == 200){
                            alert('添加成功')
                            this.$router.push('/pageAdmin/announcement')
                        }
                        })
                        .catch((res) => {
                            console.log(res)
                        });
                }
            });
        },
    },
    mounted(){
        
    }
}
</script>