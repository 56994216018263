<template>
  <div>
    <el-card class="box-card">
        <el-form :model="form" label-width="100px">
            <el-form-item label="标题" prop="imageTitle">
                <el-input v-model="form.imageTitle" style="width:400px"></el-input>
            </el-form-item>
            <el-form-item label="排序" prop="imageSerial">
                <el-input type="unmber" min="1" v-model="form.imageSerial" style="width:400px"></el-input>
            </el-form-item>
            <el-form-item label="图片" prop="xlsxFile">
                <div class="upload_box">
                    <input class="upload_input" type="file" @change="getFile"/>
                    <el-image class="upload_img" :src="imgSrc" fit="contain" v-if="imgSrc">
                        <div slot="error" class="image-slot">
                            <i class="el-icon-picture-outline"></i>
                        </div>
                    </el-image>
                    <i class="el-icon-plus upload_icon" v-if="!imgSrc"></i>
                </div>
            </el-form-item>
            <el-form-item>
                <div class="d_flex">
                    <el-button type="primary" @click="importRow" style="margin-top:50px">确认保存</el-button>
                </div>
            </el-form-item>
        </el-form>
    </el-card>



    <!-- <h1>（必填）要添加的标题：</h1>
    <el-input  rows="1" v-model="imageTitle"> </el-input>
    <h1>（必填）要添加的播放次序：</h1>
    <el-input  rows="1" v-model="imageSerial" type="number" min="1"> </el-input>
    <h1>（必填）要添加的图片：</h1>
    <div class="fileItem">
      <input type="file" ref="fileId" @change="getFile" />
    </div>
    <el-button type="primary" @click="importRow">确认保存</el-button> -->
  </div>
</template>
<script>
export default {
  data() {
    return {
        form:{
            imageTitle: "",
            imageSerial: "",
            imageType:"",
            xlsxFile:null
        },
        imgSrc:null
    };
  },
  mounted() {
    this.form.imageType = this.$route.query.type
  },
  methods: {
    getFile(e) {
      //获取file内容
      let files = e.target.files[0];
      // console.log(this.$refs.fileId.files[0])
      this.form.xlsxFile = files;
      this.imgSrc = this.getObjectURL(files);
    },
    //建立一個可存取到該file的url
    getObjectURL(file){
        let url = null
        // 下面函数执行的效果是一样的，只是需要针对不同的浏览器执行不同的 js 函数而已
        if (window.createObjectURL != undefined) {
            // basic
            url = window.createObjectURL(file)
        } else if (window.URL != undefined) {
            // mozilla(firefox)
            url = window.URL.createObjectURL(file)
        } else if (window.webkitURL != undefined) {
            // webkit or chrome
            url = window.webkitURL.createObjectURL(file)
        }
        return url
    },
    //上传文件
    importRow() {
      let that = this;
      if (that.xlsxFile == null) {
        that.$message.error("请先添加文件");
        return;
      }
      // vue 中使用 window.FormData(),否则会报 'FormData isn't definded'
      //创建一个FormData对象，然后通过append() 方法向对象中添加键值对
      let formData = new FormData();
      formData.append("file", that.form.xlsxFile);
      formData.append("imageTitle",  this.form.imageTitle);
      formData.append("imageSerial",  this.form.imageSerial);
      formData.append("imageType",  this.form.imageType);
      // that.importUrl 上传的接口url
      // console.log(formData.get("file"));
      that.axios
        .post("/image/addImage", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((res) => {
          // console.log(res);
          if(res.data.code == 200){
            alert('添加成功')
            this.$router.push('/pageAdmin/imageup')
          }
        })
        .catch((res) => {});
    },
  },
  watch:{
    imageType(n,o){
      // console.log(n,o)
    }
  }
};
</script>
<style scoped>
.upload_box{
    position:relative;
    height:140px;
}
.upload_input{
    position:absolute;
    width:140px;
    height:140px;
    opacity:0;
    z-index:1;
}
.upload_img{
    width: 140px;
    height: 140px;
    position:absolute;
    left:0;
    top:0;
    border:1px solid #ddd;
    border-radius:8px;
    overflow:hidden;
}
.upload_icon{
    position:absolute;
    left:0;
    top:0;
    width:140px;
    height:140px;
    border:1px solid #ddd;
    border-radius:8px;
    font-size:32px;
    line-height:140px;
    text-align:center;
}
</style>
