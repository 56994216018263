import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);
export default new Vuex.Store({
  state: {
    imgUrl: "http://39.102.228.161:443",
    headerText:sessionStorage.getItem("BYD_HEADER"),
    showFooter:sessionStorage.getItem("SHOW_BOOLEAN") == 'false' ? false : true,
    propShow:false
  },
  mutations: {
    SET_HEADER_TEXT: (state, text) => {
        state.headerText = text;
        sessionStorage.setItem("BYD_HEADER",text)
    },
    SET_SHOW_FOOTER:(state,showFooter)=>{
        state.showFooter = showFooter;
        sessionStorage.setItem("SHOW_BOOLEAN",showFooter)
    },
    SET_PROP_SHOW:(state,bool)=>{
        console.log(15151)
        state.propShow = bool;
    },
    SET_IMG_URL(state,value){
        state.imgUrl = value
    },
  },
  actions: {        //异步调用

  },
  modules: {  //vuex允许使用模块
    
  },
  getters:{
    headerText(state){
        return state.headerText
    },
    imgUrl(state){
        return state.imgUrl
    },
    showFooter(state){
        return state.showFooter
    },
    propShow(state){
        return state.propShow
    },
  }
});
