<template>
  <div id="wharf">
      <div style="height:66px;"></div>
      <div class="title">
        <h3>白洋淀新码头简介</h3>
        <p v-html="a">{{a}}</p>
      </div>
      <div>
          <div class="imags">
            <img src="../assets/image/play.2.png" alt="">
          </div>
          <div>
              2017年4月1日中共中央国务院决定设立雄安新区，这是以习近平同志为核心的党中央作出的一项重大历史性战略选择；是千年大计、国家大事。雄安新区的规划建设要坚持世界眼光、国际标准、中国特色、高点定位。
白洋淀作为雄安新区一张响亮的名片，自新区成立后，致力于改善旅游环境，提升白洋淀区域人与自然的和谐共生， 最大限度降低对生态环境的污染，于2019年底开始对白洋淀水域现有船舶及配套设施进行升级改造。 


该项目由北京公交集团投资建设，包括对白洋淀景区的码头升级、船舶建造，环境治理等重要改造内容，总投资额约10亿元人民币。将新建风廊桥、云廊桥、阶廊桥等12座桥梁和生态科技展示馆、游客服务中心、咖啡馆及非物质文化遗产展示馆等4座单体建筑。项目设计依托荷塘苇海的风貌格局，来演绎“白洋淀码头”的前世今生，设计既充分汲取中国传统建筑设计精华，又充分呼应当地文化特点，融入了芦苇、船屋等元素。伴随新码头建设完成，白洋淀将以崭新面貌迎接八方来客，全域生态旅游的新序幕正在缓缓拉开。
河北雄安新帆振华旅游船务管理有限公司作为白洋淀景区全面运营主体，本着在实现白洋淀生态环境治理和保护的基础上，提高服务品质，打造国际化、现代化白洋淀码头的愿景，秉承安全、环保、服务的企业核心价值观为游客带来更好的服务，规范白洋淀旅游秩序。
在这个“映日荷花别样红”的初夏，我们静候融合了京津冀全域旅游一体化发展思路的白洋淀精彩绽放，成为海内外皆知的世界级旅游品牌。

    The establishment of Xiong-an New Area, announced by the Central Committee of the Communist Party of China and the State Council on April 1, 2017, is a major historic and strategic choice made by the Communist Party of China Central Committee with Comrade Xi Jinping as the core. It is a national-level new area with national significant and a strategy crucial for a millennium to come. The planning and construction of Xiong’an New Area must adhere to the principle of global vision, international standards, Chinese characteristics and high goals.  
Since the establishment of Xiong’an New Area, Bai-yang Lake, as an representative attraction, has been committed to improving the tourism environment, enhancing the harmonious coexistence of people and nature in its area, and minimizing pollution to the ecological environment. Ships and supporting facilities in Bai-yang Lake had been upgraded since the end of 2019. 
    The upgrading project was subsidize and constructed by Beijing public transportation group, including various important renovations such as terminal upgrades, ship construction and environmental governance in Bai-yang Lake. The total investment is about 1 billion yuan. Twelve bridges including the Fenglang Bridge, the Yunlang Bridge and the Jielang Bridge, as well as 4 single buildings including the Ecological Technology Exhibition Hall, the Tourist service Center, the Café and the Intangible Cultural Heritage Exhibition Hall will be built. The design of the project is based on the pattern of the reed sea in the lotus pond to interpret the past and present of the "Bai-yang Terminal". The design not only fully absorbs the essence of traditional Chinese architectural design, but also echoes the local cultural characteristics by adding elements such as reeds and houseboats. With the completion of the new terminal, eco-tourism slowly begins and Bay-yang Lake is welcoming tourists all over the world with a new look. 
Based on the realization of Bai-yang Lake management and protection of ecological environment, Hebei Xiong’an Xinfanzhenhua Tourism Shipping Management Co. Ltd as the major management subject is committing itself to improve service quality, build an international and modern Bai-yang Lake Terminal. It upholds the company’s core values of safety, green and service to provide tourists with better services and regulate the order of Bai-yang Lake tourism. In the early summer depicted in verse “pink lotus blossoms take from sunshine a new dye”.
we are waiting for the brilliant presentation of Bai-yang Lake, which integrated with Beijing-Tianjin-Hebei’s development of reginal tourism integration. And we believe Bai-yang Lake will become a world-class tourism brand known both at home and abroad.
          </div>
      </div>
  </div>
</template>

<style scoped>
 @import '../assets/css/wharf.css'
</style>

<script>
export default {
    data(){
        return{
            a:'The Introduction of Bai-yang Lake New Terminal'
        }
    },
    methods: {
      goBack() {
          this.$router.push('/')
        // console.log('go back');
      }
    }
}
</script>