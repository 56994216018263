<template>
    <div id="details_page">
        <div id="details_h5">
            <img src="../assets/image/byd1.jpg" style="width:100%;height:auto" alt="">
            <div class="details_h5_text">
                <h3 class="details_h5_text_title">{{data.partyTitle}}</h3>
                <div class="text" v-html="data.partyContext"></div>
                <!-- <p>发表时间：{{data.partyAddTime}}</p> -->
            </div>
        </div>
        <div id="details">
            <!-- 顶部背景图 -->
            <div class="bg-img">
                <img src="../assets/image/byd1.jpg" alt="">
            </div>
            <div class="wap">
                <div>
                    <div class="title_black">
                        <div>{{detailObj.typeTitle}}</div>                    
                    </div>
                    <ul class="page-nav cl">
                        <li>
                            <a href="#/">首页</a>    
                        </li>
                        <li>></li>
                        <li>
                            <a href="#/list">{{detailObj.typeTitle}}</a>
                        </li>
                        <li>></li>
                        <li class="active" v-html="data.partyTitle">{{data.partyTitle}}</li>
                    </ul>
                    <div class="nbsp"></div>
                </div>
                <div>
                    <div v-html="data.partyContext">{{data.partyContext}}</div>
                    <div class="nbsp"></div>
                    <!-- <div class="time" v-html="data.partyAddTime">发表时间：{{data.partyAddTime}}</div> -->
                    <!-- <div>
                        <img :src="`http://39.102.228.161:443/images/${data.partyPhoto}`" alt="">
                    </div> -->
                    <div class="nbsp"></div>
                </div>
            </div>
        </div>
    </div>
</template>



<script>
export default {
    props:['typeList'],
    data(){
        return{
            data:{}
        }
    },
    methods:{
        gets(i){
            this.axios.get(`/party/queryPartyById?id=${i}`)
            .then(res=>{
                /* console.log(res) */
                this.data=res.data.data
            })
        }

    },
    computed:{
        detailObj(){
            let obj = this.typeList.find(val=>{return val.typeId == 3})
            if(obj === undefined){
                return {}
            }else{
                return obj
            }
        },
    },
    mounted(){
        
        this.gets(this.$route.query.ID);
    }
}
</script>


<style scoped lang="less">
@import '../assets/css/details.css';
@media (min-width: 751px) {
    #details_h5{
        display: none;
    }
}
@media (max-width: 750px) {
    #details{
        display: none;
    }
}
.details_h5_text{
    padding:0 8px;
    font-size: 14px;
    .details_h5_text_title{
        font-weight: bold;
        text-align:center;
        margin-top:10px;
    }
    .text{
        padding:10px 0;
    }
}
</style>