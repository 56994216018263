
<template>
        <el-container class="h_100">
            <div class="h_100" style="background-color:#2d3a4b">
            <!-- <el-aside width="200px"> -->
                <el-menu :default-active="activeIndex" background-color="#2d3a4b" unique-opened text-color="#fff" style="width:200px">
                    <el-submenu index="1">
                        <template slot="title">
                            <i class="el-icon-location"></i>
                            <span>用户管理</span>
                        </template>
                        <el-menu-item index="/pageAdmin/user" @click="urlTo('/pageAdmin/user')">
                            <span>管理人员</span>
                        </el-menu-item>
                    </el-submenu>
                    <el-submenu index="2">
                        <template slot="title">
                            <i class="el-icon-location"></i>
                            <span>信息管理</span>
                        </template>
                        <el-menu-item index="/pageAdmin/party" @click="urlTo('/pageAdmin/allparty')">
                            <span>信息列表</span>
                        </el-menu-item>
                    </el-submenu>
                    <el-submenu index="3">
                        <template slot="title">
                            <i class="el-icon-location"></i>
                            <span>数据上传</span>
                        </template>
                        <el-menu-item index="/pageAdmin/videoup" @click="urlTo('/pageAdmin/videoup')">
                            <span>视频数据</span>
                        </el-menu-item>
                        <el-menu-item index="/pageAdmin/imageup" @click="urlTo('/pageAdmin/imageup')">
                            <span>轮播数据</span>   
                        </el-menu-item>
                        <el-menu-item index="/pageAdmin/notice" @click="urlTo('/pageAdmin/notice')">
                            <span>公告数据</span>
                        </el-menu-item>
                    </el-submenu>
                    <el-menu-item index="999" @click="backHome">
                        <i class="el-icon-location"></i>
                        <span>退出登陆</span>
                    </el-menu-item>
                </el-menu>
                <!-- <el-collapse v-model="activeName">
                    <el-collapse-item title="用户管理" name="1">
                        <div class="div1" style="background-color:#2d3a4b">
                            <router-link to="/pageAdmin/user">管理人员</router-link>
                        </div>
                    </el-collapse-item>
                    <el-collapse-item title="信息管理" name="2">
                        <div class="div1" style="background-color:#2d3a4b">
                            <router-link to="/pageAdmin/party">党建信息</router-link>
                        </div>
                        <div class="div1" style="background-color:#2d3a4b">
                            <router-link to="/pageAdmin/news">新闻信息</router-link>
                        </div>
                        <div class="div1" style="background-color:#2d3a4b">
                            <router-link to="/pageAdmin/announcement">游玩信息</router-link>
                        </div>
                    </el-collapse-item>
                    <el-collapse-item title="数据上传" name="4">
                        <div class="div1" style="background-color:#2d3a4b">
                            <router-link to="/pageAdmin/videoup">视频数据</router-link>
                        </div>
                        <div class="div1" style="background-color:#2d3a4b">
                            <router-link to="/pageAdmin/imageup">图片数据</router-link>
                        </div>
                        <div class="div1" style="background-color:#2d3a4b">
                            <router-link to="/pageAdmin/notice">公告数据</router-link>
                        </div>
                    </el-collapse-item>
                </el-collapse> -->
            <!-- </el-aside> -->
            </div>
            <el-main>
                <router-view :typeList="typeList" @getTypeList="getList"/>
            </el-main>
        </el-container>
</template>
<script>
import paging from '../components/paging.vue'
import User from '../components/user.vue'
export default {
    data(){
        return{
            typeList: [],
            activeIndex:'/pageAdmin/user'
        }
    },
    components:{
        User,
        paging
    },
    mounted(){
        this.getList()
        let arr = ['/pageAdmin/user','/pageAdmin/allparty','/pageAdmin/videoup','/pageAdmin/imageup','/pageAdmin/notice']
        let str = arr.find(val=>{return window.location.hash.indexOf(val) >= 0})
        if(str !== undefined){
            this.activeIndex = str
        }
    },
    methods:{
        urlTo(url){
            if(window.location.hash.indexOf(url) == -1){
                this.$router.replace({ path: url});
                this.activeIndex = url
            }
        },
        backHome(){
            console.log(this.$router)
            let url = this.activeIndex
            this.activeIndex = '999'
            this.$confirm('是否退出登陆？ 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                localStorage.setItem('islogin',null)
                sessionStorage.setItem('islogin',null)
                localStorage.setItem("BYD_TOKEN_VALUE",null)
                // let backlen = window.history.length - 1;
                // window.history.go(-backlen);
                this.$router.replace({ path: '/' });
                this.$message({
                    type: 'success',
                    message: '退出登陆!'
                });
            }).catch(() => {
                this.activeIndex = url
            });
            console.log(333,this.activeIndex)
        },
        getList() {
            this.axios.get('/plateType/queryPlateTypeList',{params:{pageNum:1,pageSize:20}}).then(res=>{
                console.log(1111,res)
                this.typeList = res.data.data.list.map(val=>{
                    val.typePhoto = val.typePhoto ? this.$store.state.imgUrl + "/images/" + val.typePhoto : null;
                    return val
                })
                console.log("分类表",res);
            }).catch(err=>{
                console.log('报错',err)
            })
        },
    }
}
</script>
<style>
*{
    padding: 0; margin: 0;
}
.el-collapse-item__content{
    padding-bottom: 0px !important;
}
.el-collapse-item__header{
    background: #2d3a4b !important;
    border: none !important;
    color:#eee !important;
    font-size: 16px !important;
   
}
.el-collapse-item .div1{
    background: #2d3a4b !important;
    text-align: center;
    height: 50px;
     color:#eee !important;
     cursor: pointer;
}
</style>