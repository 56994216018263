<template>
  <div v-if="this.data">
    <el-button type="primary" @click="addstroll">新增游玩信息</el-button>

    <el-table :data="list" border style="width: 100%">
      <el-table-column fixed prop="scenicId" label="ID" width="100">
      </el-table-column>
      <el-table-column prop="scenicTitle" label="标题" width="200">
      </el-table-column>
      <el-table-column prop="scenicSynopsis" label="游玩概括" width="600">
      </el-table-column>
      <!-- <el-table-column prop="scenicContext" label="内容" width="600">
      </el-table-column> -->
      <el-table-column label="图片" width="500">
        <template slot-scope="scope">
          <el-popover placement="top-start" title="" trigger="hover">
            <img :src="scope.row.scenicPhoto" alt="" style="width: 300px;" />
            <img
              slot="reference"
              :src="scope.row.scenicPhoto"
              style="width: 200px;"
            />
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column prop="scenicShow" label="状态" width="100">
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="100">
        <template slot-scope="scope">
          <el-button @click="handleClick(scope.row)" type="text" size="small"
            >编辑</el-button
          >
          <el-button type="text" size="small" @click="del(scope.row.scenicId)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="pagess">
      <ul>
        <li @click="upList(1)">首页</li>
        <li v-show="!data.isFirstPage" @click="minuspage">上一页</li>
        <li
          :class="{ active: data.pageNum == item }"
          v-for="(item, index) in data.navigatepageNums.length"
          :key="index"
          @click="upList(item, index)"
        >
          {{ item }}
        </li>
        <li v-show="!data.isLastPage" @click="addpage">下一页</li>
      </ul>
      <div class="jump">
        <span>跳转到第</span><input type="text" v-model="pageNum" /><span
          >页</span
        >
        <button class="search" @click="search">搜索</button>
      </div>
      <div class="clear"></div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.getNews(1);
  },
  methods: {
    del(id) {
      // console.log(id);
      let result = confirm(`是否删除ID为${id}的数据，如果删除，无法恢复数据！`);
      if (result) {
        this.axios.delete(`/scenic/deleteScenic?id=${id}`).then((res) => {
          if (res.data.code == 200) {
            alert(`成功删除ID为${id}的数据`);
            this.$router.go(0);
            // this.$router.push( "/pageAdmin/announcement")
          }
        });
      }
    },
    addstroll() {
      this.$router.push("/pageAdmin/strollAdd");
    },
    handleClick(row) {
      // console.log(row);
      this.$router.push({ name: "StrollChange", params: { row } });
    },
    // gets() {
    //   this.axios
    //     .get("/scenic/queryScenicList?pageNum=1000&pageSize=1000")
    //     .then((res) => {
    //       console.log(res);
    //       const { list } = res.data.data;
    //       //   this.tableData=res.data.data.list;
    //       list.forEach((element) => {
    //         element.scenicPhoto =
    //           "http://39.102.228.161:443/images/" + element.scenicPhoto;
    //         element.scenicShow = element.scenicShow == 1 ? "开启" : "关闭";
    //       });
    //         // console.log(list.scenicPhoto)
    //       this.tableData = list;
    //     })
    //     .catch((err) => {
    //       console.error(err);
    //     });
    // },
    getNews(Num) {
      this.axios
        .get(`/scenic/queryScenicList?pageNum=${Num}&pageSize=6`)
        .then((res) => {
          // console.log(res);
          const { list } = res.data.data;
          //   this.tableData=res.data.data.list;
          list.forEach((element) => {
            element.scenicPhoto =
              this.$store.state.imgUrl + "/images/" + element.scenicPhoto;
            element.scenicShow = element.scenicShow == 1 ? "开启" : "关闭";
          });
          // console.log(list.scenicPhoto)
          this.list = list;
          this.data = res.data.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    upList(Num) {
      let that = this;
      let data = that.data;
      // console.log(data)
      that.getNews(Num);
    },
    minuspage() {
      let that = this;
      let data = that.data;
      if (data.isFirstPage) {
        return;
      }
      that.upList(that.pageNum - 1);
    },
    addpage() {
      let that = this;
      let data = that.data;
      if (data.isLastPage) {
        return;
      }
      that.upList(that.pageNum + 1);
    },
    search() {
      this.upList(this.pageNum);
    },
  },

  data() {
    return {
      list: [],
      multipleSelection: [],
      data: null,
      pageNum: 1,
    };
  },
};
</script>
<style scoped>
.pagess {
  padding: 35px;
  width: 1000px;
}
.pagess ul {
  margin: 0 auto;
  width: 50%;
}
.pagess ul li {
  margin-left: 1%;
  float: left;
  padding: 6px 3%;
  cursor: pointer;
  border: 1px solid #e8e8e8;
  font-size: 0.8rem;
}
.pagess ul li:hover {
  border: 1px solid #666;
  color: rgb(34, 30, 30);
}
.active {
  background: #e8e8e8;
}
.jump {
  line-height: 33px;
  float: left;
}
.jump input {
  border: 1px solid #e8e8e8;
  color: rgb(34, 30, 30);
  width: 40px;
  height: 33px;
  text-align: center;
}
.search {
  border: 1px solid #e8e8e8;
  color: rgb(34, 30, 30);
  height: 33px;
  text-align: center;
  padding: 10px;
  margin-left: 10px;
}
.search:hover {
  /* border:1px solid #666; */
  color: rgb(34, 30, 30);
  background: #e8e8e8;
  cursor: pointer;
}
</style>
