<template>
  <div v-if="this.data">
    <el-button type="primary" @click="addnews">新增资讯信息</el-button>
    <el-table :data="list" tooltip-effect="dark" style="width: 100%" border>
      <!-- <el-table-column type="selection" width="55"> </el-table-column> -->
      <el-table-column prop="newsId" label="资讯ID" width="120">
      </el-table-column>
      <el-table-column prop="newsTitle" label="资讯标题" width="120">
      </el-table-column>
      <!-- <el-table-column prop="newsContext" label="资讯内容"> </el-table-column> -->
      <el-table-column prop="newsSynopsis" label="资讯概括"> </el-table-column>
      <el-table-column label="图片">
        <template slot-scope="scope">
          <el-popover placement="top-start" title="" trigger="hover">
            <img :src="scope.row.newsPhoto" alt="" style="width: 300px;" />
            <img
              slot="reference"
              :src="scope.row.newsPhoto"
              style="width: 200px;"
            />
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="100">
        <template slot-scope="scope">
          <el-button @click="handleClick(scope.row)" type="text" size="small"
            >编辑</el-button
          >
          <el-button @click="removeClick(scope.row)" type="text" size="small"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="pagess">
      <ul>
        <li @click="upList(1)">首页</li>
        <li v-show="!data.isFirstPage" @click="minuspage">上一页</li>
        <li
          :class="{ active: data.pageNum == item }"
          v-for="(item, index) in data.navigatepageNums.length"
          :key="index"
          @click="upList(item, index)"
        >
          {{ item }}
        </li>
        <li v-show="!data.isLastPage" @click="addpage">下一页</li>
      </ul>
      <div class="jump">
        <span>跳转到第</span><input type="text" v-model="pageNum" /><span
          >页</span
        >
        <button class="search" @click="search">搜索</button>
      </div>
      <div class="clear"></div>
    </div>
    <!-- <div style="margin-top: 20px">
      <el-button @click="toggleSelection([tableData[1], tableData[2]])"
        >切换第二、第三行的选中状态</el-button
      >
      <el-button @click="toggleSelection()">取消选择</el-button>
    </div> -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      list: [],
      multipleSelection: [],
      data: null,
      pageNum: 1,
    };
  },
  mounted() {
    this.getNews(1);
  },
  methods: {
    // toggleSelection(rows) {
    //   if (rows) {
    //     rows.forEach((row) => {
    //       this.$refs.multipleTable.toggleRowSelection(row);
    //     });
    //   } else {
    //     this.$refs.multipleTable.clearSelection();
    //   }
    // },
    // handleSelectionChange(val) {
    //   this.multipleSelection = val;
    // },
    // getnewslist() {
    //   this.axios
    //     .get("/news/queryNewsList?pageNum=1000&pageSize=1000")
    //     .then((res) => {
    //       console.log(res);
    //       const { list, pageNum, pageSize } = res.data.data;
    //       list.forEach(element => {
    //         element.newsPhoto = 'http://39.102.228.161:443/images/' + element.newsPhoto
    //       });
    //       this.tableData = list;
    //     });
    // },
    handleClick(row) {
      // console.log(row);
      this.$router.push({ name: "NewsChange", params: { row } });
    },
    removeClick(row) {
      // console.log(row.newsId);
      let result = confirm(
        `是否删除ID为${row.newsId}的数据，如果删除，无法恢复数据！`
      );
      if (result) {
        this.axios.delete(`/news/deleteNews?id=${row.newsId}`).then((res) => {
          if (res.data.code == 200) {
            alert(`成功删除ID为${row.newsId}的数据`);
            this.$router.go(0);
          }
        });
      }
    },
    addnews() {
      this.$router.push("/pageAdmin/newsAdd");
    },
    //分页
    getNews(Num) {
      let that = this;
      that.axios
        .get(`/news/queryNewsList?pageNum=${Num}&pageSize=6`)
        .then((res) => {
          // console.log(res);
          const { list, pageNum, pageSize, navigatepageNums } = res.data.data;
          list.forEach((element) => {
            element.newsPhoto =
              this.$store.state.imgUrl + "/images/" + element.newsPhoto;
          });
          that.list = list;
          that.data = res.data.data;
          // console.log(list)
          // console.log(that.data)
        });
    },
    upList(Num) {
      let that = this;
      let data = that.data;
      // console.log(data)
      that.getNews(Num);
    },
    minuspage() {
      let that = this;
      let data = that.data;
      if (data.isFirstPage) {
        return;
      }
      that.upList(that.pageNum - 1);
    },
    addpage() {
      let that = this;
      let data = that.data;
      if (data.isLastPage) {
        return;
      }
      that.upList(that.pageNum + 1);
    },
    search() {
      this.upList(this.pageNum);
    },
  },
};
</script>
<style scoped>
.pagess {
  padding: 35px;
  width: 1000px;
}
.pagess ul {
  margin: 0 auto;
  width: 50%;
}
.pagess ul li {
  margin-left: 1%;
  float: left;
  padding: 6px 3%;
  cursor: pointer;
  border: 1px solid #e8e8e8;
  font-size: 0.8rem;
}
.pagess ul li:hover {
  border: 1px solid #666;
  color: rgb(34, 30, 30);
}
.active {
  background: #e8e8e8;
}
.jump {
  line-height: 33px;
  float: left;
}
.jump input {
  border: 1px solid #e8e8e8;
  color: rgb(34, 30, 30);
  width: 40px;
  height: 33px;
  text-align: center;
}
.search {
  border: 1px solid #e8e8e8;
  color: rgb(34, 30, 30);
  height: 33px;
  text-align: center;
  padding: 10px;
  margin-left: 10px;
}
.search:hover {
  /* border:1px solid #666; */
  color: rgb(34, 30, 30);
  background: #e8e8e8;
  cursor: pointer;
}
</style>
