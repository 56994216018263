<template>
  <div id="louts_page">
    <div id="louts_h5">
        <div class="louts_h5_box">
            <van-sidebar v-model="activeKey" @change="onChange">
                <van-sidebar-item v-for="(item, index) in List" :key="index" :title="item.scenicTitle"/>
            </van-sidebar>
            <div class="louts_h5_body">
                <img :src="`${$store.state.imgUrl}/images/${data.scenicPhoto}`" alt="">
                <div style="margin: 10px 0;" v-html="data.scenicContext"></div>
            </div>
        </div>
    </div>
    <div id="louts">
        <!-- <div style="height:66px;"></div> -->

        <div class="louts_title" v-html="data.scenicTitle">{{ data.scenicTitle }}</div>
        <div style="width:100%;margin:0 auto;display:flex">
            <div class="sidebar1" v-if="List">
                <div
                :class="['sidebar1-item', cur === index + 1 ? 'side-active' : '']"
                v-for="(item, index) in List"
                :key="index"
                @click="goBack(index + 1)"
                >
                <p>{{ item.scenicTitle }}</p>
                </div>
            </div>
            <div class="center1">
                <div class="wid">
                <img
                    class="imgs"
                    :src="`${$store.state.imgUrl}/images/${data.scenicPhoto}`"
                />
                </div>
                <div style="margin: 20px 0;" v-html="data.scenicContext">
                {{ data.scenicContext }}
                </div>
            </div>
            <!-- <div class="clear"></div> -->
        </div>
    </div>
  </div>
</template>

<style scoped>
@import "../assets/css/lotus.css";
</style>

<script>
export default {
  data() {
    return {
      data: {},
      List: null,
      cur: null,
      activeKey:null,
    };
  },
  computed:{
  },
  methods: {
    onChange(e){
        this.data = {}
        this.goBack(e + 1)
    },
    goBack(i) {
      console.log(i);
      this.cur = i;
      this.axios
        .get(`/scenic/queryScenicById?id=${i}`)
        .then((res) => {
        //    console.log(res);
          this.data = res.data.data;
          this.$store.commit("SET_HEADER_TEXT",res.data.data.scenicTitle)
        })
        .catch((err) => {
          //  console.error(err);
        });
    },
    scenicList() {
      this.axios
        .get(`/scenic/queryScenicList?pageNum=1&pageSize=10`)
        .then((res) => {
          // console.log(res);
          this.List = res.data.data.list;
        })
        .catch((err) => {
          //  console.error(err);
        });
    },
  },
  mounted() {
    this.activeKey = Number(this.$route.query.ID) - 1
    this.goBack(Number(this.$route.query.ID));
    this.scenicList();
  },
};
</script>
<style scoped lang="less">
@media (min-width: 751px) {
    #louts_h5{
        display: none;
    }
}
@media (max-width: 750px) {
    #louts{
        display: none;
    }
}
#louts_page{
    min-height: 100%;
    box-sizing: border-box;
}
#louts{
    padding-top: 66px;
}
.louts_title{
    text-align: center;font-size: 24px;margin: 30px 0;
}
.louts_h5_box{
    display: flex;
    .louts_h5_body{
        flex: 1;
        overflow: hidden;
        padding: 8px;
    }
}
/deep/ .van-sidebar-item--select::before{
        background-color:green
    }
</style>