<template>
  <div>
    <el-table
      :data="tableData"
      stripe
      style="width: 100%;height:300px;overflow-y: scroll;"
    >
      <el-table-column prop="id" label="序号" width="100"> </el-table-column>
      <el-table-column prop="user" label="游客留言" width="200">
      </el-table-column>
      <el-table-column prop="Admin" label="结办人" width="180">
      </el-table-column>
      <el-table-column prop="time" label="时间" width="180"> </el-table-column>
      <el-table-column prop="stage" label="状态" width="180"> </el-table-column>
    </el-table>
    <!-- <div style="margin-left:45%">
      <el-pagination layout="prev, pager, next" :total="1000" page-size=6>
      </el-pagination>
    </div> -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      tableData: [],
    };
  },
  mounted() {
    this.gettable();
  },
  methods: {
    gettable() {
      this.axios
        .get(
          "/proposal/queryProposalList?pageNum=1&pageSize=9"
        )
        .then((res) => {
          // console.log(res);
          let tableData = [];
          res.data.data.list.forEach((element) => {
            tableData.push({
              id: "ID:" + element.proposalId,
              user: element.proposalUser + ":" + element.proposalContent,
              time: element.proposalCreateTime,
              Admin: element.proposalAdmin,
              stage: element.proposalStage == 2 ? "已办理" : "未办理",
            });
          });
          this.tableData = tableData;
        });
    },
  },
};
</script>
<style scoped>
.el-pager li.active {
  color: #666 !important;
}
</style>
