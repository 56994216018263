<template>
    <div class="frath">
        <div>
            <div class="zi">时间</div>
            <div>{{data.weatherDate}}</div>
        </div>
        <div>
            <div class="zi">ID</div>
            <div>{{data.weatherId}}</div>
        </div>
        <div>
            <div class="zi">景区是否开放</div>
            <el-row>
                <el-button type="primary" @click="on">开起</el-button>
                <el-button type="danger" @click="off">关闭</el-button>
            </el-row>
        </div>
        <div>当前状态:{{swi==1 ? "开" : "关"}}</div>
    </div>
</template>
<style scoped>
    .frath{
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-evenly;
    }
    .zi{
        margin-bottom: 20px;
    }
</style>

<script>
  export default {
    data() {
      return {
        value: true,
        swi:'0',
        id:'',
        data:{}
      }
    },
    methods:{
        getswitch(){
            this.axios.get('/init')
            .then(res => {
                this.data=res.data.data.weather
                this.id=res.data.data.weather.weatherId
                this.swi=res.data.data.weather.isOpen
                // console.log(res)
            })
            .catch(err => {
                console.error(err); 
            })
        },
        on(){
            // console.log(this.id)
            this.axios.put('/weather/updateWeather',{"isOpen": 1,"weatherId": this.id})
            .then(res => {
                // console.log(this.swi)
                // console.log(res);
                if(res.data.code==200){
                    this.swi=1
                }
            })
            .catch(err => {
                console.error(err); 
            })
        },
        off(){
             this.axios.put('/weather/updateWeather',{"isOpen": 0,"weatherId": this.id})
            .then(res => {
                // console.log(this.swi)
                // console.log(res);
                if(res.data.code==200){
                    this.swi=0
                }
            })
            .catch(err => {
                console.error(err); 
            })
        },
         
    },
    mounted(){
        this.getswitch()
    }
  };
</script>