<template>
  <div>
    <el-card class="box-card">
        <el-form :model="params" label-width="100px" :rules="rules" ref="ruleForm">
            <el-form-item label="标题" prop="newsTitle">
                <el-input v-model="params.newsTitle" style="width:400px"></el-input>
            </el-form-item>
            <el-form-item label="时间" prop="newsAddTime">
                <el-date-picker v-model="params.newsAddTime" type="datetime" placeholder="选择日期" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
            </el-form-item>
            <el-form-item label="概括" prop="newsSynopsis">
                <el-input type="textarea" :rows="3" v-model="params.newsSynopsis"></el-input>
            </el-form-item>
            <el-form-item label="内容" prop="newsContext">
                <quill @quill = "quillVal" :Pquillval="params.newsContext"></quill>
            </el-form-item>
            <el-form-item label="图片" prop="xlsxFile">
                <input type="file" ref="fileId" @change="getFile" />
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="importRow" >确认保存</el-button>
            </el-form-item>
        </el-form>
    </el-card>
    <!-- <h1>（必填）要添加的资讯标题：</h1>
    <el-input type="textarea" :rows="5" v-model="newsTitle"> </el-input>
    <h1>（必填）要添加的资讯概括：</h1>
    <el-input type="textarea" :rows="5" v-model="newsSynopsis"> </el-input>
    <h1>（必填）要添加的资讯内容：</h1>
    <quill @quill = "quillVal" :Pquillval="newsContext"></quill>
    
    <h1>（必填）资讯信息的图片：</h1>

    <div class="fileItem">
      <input type="file" ref="fileId" @change="getFile" />
    </div>
    <el-button type="primary" @click="importRow" >确认保存</el-button> -->
  </div>
</template>
<script>
import Quill from './Quill.vue';
export default {
  data() {
    return {
        params:{
            newsTitle: "", 
            newsContext: "",
            newsPath: "",
            newsSynopsis:"",
            newsAddTime:null,
            xlsxFile:null
        },
        rules:{
            newsTitle:{ required: true, message: '标题不能为空', trigger: 'blur' },
            newsAddTime:{required: true, message: '时间不能为空', trigger: 'blur'},
            xlsxFile:{ required: true, message: '图片不能为空', trigger: 'blur' },
            newsSynopsis:{ required: true, message: '概括不能为空', trigger: 'blur' },
            newsContext:{ required: true, message: '内容不能为空', trigger: 'blur' }
        }
    };
  },
  mounted() {},
  methods: {
    // 副文本传参
    quillVal(data){
      // console.log(data)
      this.params.newsContext = data.html
    },
    getFile() {
      //获取file内容
      let files = this.$refs.fileId.files[0];
      // console.log(this.$refs.fileId.files[0])
      this.params.xlsxFile = files;
    },
    //上传文件
    importRow() {
      // console.log(this.newsContext)
      let that = this;
      this.$refs.ruleForm.validate((valid) => {
            if (valid) {
                let formData = new FormData();
                formData.append("file", that.params.xlsxFile);
                formData.append("newsContext",  this.params.newsContext);
                formData.append("newsPath",  this.params.newsPath);
                formData.append("newsTitle",  this.params.newsTitle);
                formData.append("newsSynopsis",  this.params.newsSynopsis);
                formData.append("newsAddTime",  this.params.newsAddTime);
                
                formData.append("newsTop",  0);
                // that.importUrl 上传的接口url
                // console.log(formData.get("file"));
                that.axios
                    .post("/news/addNews", formData, {
                    headers: { "Content-Type": "multipart/form-data" },
                    })
                    .then((res) => {
                    // console.log(res);
                    if(res.data.code == 200){
                        alert('添加成功')
                        this.$router.push('/pageAdmin/news')
                    }
                    })
                    .catch((res) => {});
            }
        });
    },
  },
  components:{
    Quill
  }
};
</script>
<style scoped></style>
