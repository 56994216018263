<template>
  <div id="foreign">
      <div style="height:66px;"></div>
      <div class="imgs">
          <img src="../assets/image/play.4.png" alt="">
      </div>
      <div>
          异国风情园位于国家5A级景区--白洋淀内，是一个极富异域风情、参与性强的的休闲娱乐景点，在这里您可亲眼目睹头入鳄口，狮子猎捕，看心惊胆战的高空无保护走钢丝，体验毛骨耸立的次声波视听室、还可欣赏泰国民间歌舞表演，欧洲女子泥跤、神秘小屋、攀岩、高空弹跳等。
这里还有一个著名的以抗日战争时期的白洋淀为大背景的新建景区—嘎子印象，是白洋淀旅游一颗崭新的明珠。
休闲岛上别样风情，水天月色，如诗如画。娱乐项目有岛上住宿、餐饮、购物，游泳、迷宫、匹特博、猎炮打鸭、汽动力高射炮、荷花、鱼类观赏，垂钓、划船等设施齐全，客房整洁、卫生舒适；休闲岛又是白洋淀的一处美食岛。
      </div>
  </div>
</template>
<style scoped>
@import '../assets/css/foreign.css'
</style>

<script>
  export default {
    methods: {
      goBack() {
          this.$router.push('/')
        // console.log('go back');
      }
    }
  }
</script>