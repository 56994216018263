<template>
    <div>
        <el-card class="box-card">
            <el-form ref="form" :model="form" label-width="80px">
                <!-- <el-form-item label="上传时间">
                    <el-col :span="11">
                    <el-date-picker type="date" placeholder="选择日期" v-model="form.noticeShowTime" style="width: 100%;"></el-date-picker>
                    </el-col>
                        <el-col :span="11">
                    </el-col>
                </el-form-item> -->
                <el-form-item label="标题" prop="noticeTitle">
                    <el-input v-model="form.noticeTitle"></el-input>
                </el-form-item>
                <el-form-item label="概括" prop="noticeContext">
                    <el-input type="textarea" v-model="form.noticeContext"></el-input>
                </el-form-item>
                <el-form-item label="类型" prop="noticeTop">
                    <el-radio-group v-model="form.noticeTop">
                    <el-radio :label="1" >TOP</el-radio>
                    <el-radio :label="0">NEW</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="状态" prop="noticeShow">
                    <el-radio-group v-model="form.noticeShow">
                        <el-radio :label="0" >不显示</el-radio>
                        <el-radio :label="1">显示</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="详情" prop="noticeDetail">
                    <quill @quill="quillVal" :Pquillval="form.noticeDetail"></quill>
                </el-form-item>
                <el-form-item>
                    <div style="margin-top:45px" class="d_flex jc_end">
                        <el-button type="primary" @click="onSubmit">提交</el-button>
                    </div>
                </el-form-item>
            </el-form>
        </el-card>
    </div>
</template>
<script>
import Quill from './Quill.vue';
  export default {
    data() {
      return {
        form: {
            // noticeShowTime:'',//时间
            noticeTitle:'',//标题
            noticeContext:'',//内容
            noticeTop:'',//类型
            noticeShow:'',//显示
            noticeDetail:''//详情
        },
      }
    },
    components:{
        Quill
    },
    methods: {
        onSubmit() {
            //   console.log(this.form)
            this.axios.put('/notice/updateNotice',{
                "noticeContext": this.form.noticeContext,
                "noticeId": this.form.noticeId,
                "noticeShow": this.form.noticeShow,
                "noticeTitle": this.form.noticeTitle,
                "noticeTop": this.form.noticeTop,
                "noticeDetail":this.form.noticeDetail
            })
            .then(res => {
                //   console.log(res)
                //   console.log(this.form)
                if(res.data.code==200){
                    alert('添加成功')
                    this.$router.push('/pageAdmin/notice')
                }
            })
            .catch(err => {
                console.error(err); 
            })
            // console.log(this.form)
        },
        getlook(id){
            this.axios.get('/notice/queryNoticeById',{params:{id}})
            .then(res => {
                this.form=res.data.data
                console.log(33,this.form)
                //   console.log(this.form)
            })
            .catch(err => {
                console.error(err); 
            })
        },
        quillVal(data){
            // console.log(data)
            this.form.noticeDetail = data.html
        },
    },
    mounted(){
        this.getlook(this.$route.query.id);
    },
  }
</script>