
<template>
    <div>
        <el-card class="box-card">
            <el-button type="primary" @click="adduser">新增</el-button>
            <el-table :header-cell-style="{backgroundColor:'#f5f7fa'}" stripe tooltip-effect="light" :data="tableData" style="width: 100%;margin-top:25px" size='small'>
                <el-table-column
                type="selection"
                width="55">
                </el-table-column>
                <el-table-column
                prop="adminId"
                label="用户ID"
                width="180">
                </el-table-column>
                <el-table-column
                prop="adminName"
                label="用户名"
                width="180">
                </el-table-column>
                
                <el-table-column
                header-align="center"
                prop="adminTime"
                label="注册时间"
                align="center"
                >
                </el-table-column>
                <el-table-column
                header-align="center"
                prop="adminType"
                label="状态"
                align="center"
                >
                </el-table-column>
                <!-- <el-table-column
                    fixed="right"
                    label="操作"
                    width="100">
                    <template slot-scope="scope">
                        <el-button @click="handleClick(scope.row)" type="text" size="small">查看</el-button>
                        <el-button type="text" size="small">编辑</el-button>
                    </template>
                    </el-table-column> -->
            </el-table>
        </el-card>
    </div>
</template>
<script>
export default {
    data(){
        return{
            tableData: []
        }
    },
    mounted(){
        this.getadmin()
    },
    methods:{
        getadmin(){
            this.axios.get('/admin/queryAdminList?pageNum=1&pageSize=100000').then(res=>{
                // console.log(res)
                const { list,pageNum,pageSize }=res.data.data
                list.forEach(element => {
                   element.adminType = element.adminType == '1'?'已激活':'未激活'
                });
                this.tableData=list.sort((a,b)=>{
                    return a.adminId-b.adminId
                })
                // console.log(this.tableData)
            })
        },
        adduser(){
            this.$router.push('/pageAdmin/register')
        }
    }
}
</script>
<style>
*{
    padding: 0; margin: 0;
}

</style>