import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Lotus from '../views/Lotus.vue'
import Wharf from '../views/Wharf.vue'
import Affectionate from '../views/Affectionate.vue'
import Foreign from '../views/Foreign.vue'
import Culture from '../views/Culture.vue'
import Amusement from '../views/Amusement.vue'
import list from '../views/List.vue'
import lists from '../views/Lists.vue'
import HelloWorld from '../components/HelloWorld.vue'
import Login from "../views/Login.vue";
import Details from "../views/Details.vue";
import Detailsy from "../views/Detailsy.vue";
import ticket from "../views/Ticket.vue";
import PlayList from "../views/PlayList.vue";



import Advise from "../components/Advise.vue";
import Announcement from "../components/Announcement.vue";
import Imageup from "../components/Imageup.vue";
import News from "../components/News.vue";
import Party from "../components/Party.vue";
import MsgType from "../components/MsgType.vue";
import Videoup from "../components/Videoup.vue";
import User from "../components/user.vue";
import Admin from "../views/Admin.vue";
import Register from "../components/Register.vue";
import PartyChange from "../components/PartyChange.vue";
import PartyAdd from "../components/PartyAdd.vue";
import Switch from "../components/Switch.vue";
import Notice from "../components/Notice.vue";
import Notice_add from "../components/Notice_add.vue";
import Notice_update from "../components/Notice_update.vue";
import NewsAdd from "../components/newsAdd.vue";
import NewsChange from "../components/NewsChange.vue";
import Addimage from "../components/addimage.vue";
import ImageChange from "../components/ImageChange.vue";
import Navigation from "../components/Navigation.vue";
import paging from "../components/paging.vue";
import richtext from "../components/Richtext.vue";
import footer from "../components/Footer.vue";
import header from "../components/Header.vue";
import StrollAdd from "../components/StrollAdd.vue";
import StrollChange from "../components/StrollChange.vue";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta:{
      showNav: true
    }
  },
  {
    path:'/lotus',
    name:'Lotus',
    component:()=>import('../views/Lotus.vue')
  },
  {
    path:'/playList',
    name:'PlayList',
    component:()=>import('../views/PlayList.vue')
  },
  {
    path:'/wharf',
    name:'Wharf',
    component:()=>import('../views/Wharf.vue')
  },
  {
    path:'/affectionate',
    name:'Affectionate',
    component:()=>import('../views/Affectionate.vue')
  },
  {
    path:'/foreign',
    name:'Foreign',
    component:()=>import('../views/Foreign.vue')
  },
  {
    path:'/culture',
    name:'Culture',
    component:()=>import('../views/Culture.vue')
  },
  {
    path:'/amusement',
    name:'Amusement',
    component:()=>import('../views/Amusement.vue')
  },
  {
    path:'/helloWorld',
    name:'HelloWorld',
    component:()=>import('../components/HelloWorld.vue')
  },
  {
    path: "/login",
    name: "Login",
    component: ()=>import('../views/Login.vue'),
    meta:{
      showNav: true
    }
  },
  {
    path: "/details",
    name: "Details",
    component: ()=>import('../views/Details.vue'),
  },
  {
    path: "/noticedetail",
    name: "noticedetail",
    component: ()=>import('../views/noticedetail.vue'),
  },
  {
    path: "/detailsy",
    name: "Detailsy",
    component: ()=>import('../views/Detailsy.vue'),
  },
  {
    path: '/navigation',
    name: 'Navigation',
    component: Navigation
  },
  {
    path: '/paging',
    name: 'paging',
    component: () => import('../components/paging.vue')
  },
  {
    path: '/richtext',
    name: 'Richtext',
    component: () => import('../components/Richtext.vue')
  },
  {
    path: '/footer',
    name: 'Footer',
    component: () => import('../components/Footer.vue')
  },
  {
    path: '/header',
    name: 'Header',
    component: () => import('../components/Header.vue')
  },
  {
    path: '/list',
    name: 'List',
    component: () => import('../views/List.vue')
  },
  {
    path: '/lists',
    name: 'Lists',
    component: () => import('../views/Lists.vue')
  },
  {
    path: '/ticket',
    name: 'Ticket',
    component: () => import('../views/Ticket.vue')
  },
  {
    path: "/pageAdmin",
    name: "Admin",
    component: Admin,
    meta:{
      showNav: true
    },
    beforeEnter: (to, from, next) => {
        console.log(9999)
      //       console.log(!(from.path==='/login'),from.path!='/login',window.sessionStorage.getItem('islogin'))
            if (from.path!='/login' && !(window.sessionStorage.getItem('islogin')==1 || window.localStorage.getItem('islogin')==1)) {
                next({
                    path: '/login'
                })
            } else {
                next()
            }
        },
    children: [
      {
        path: "partyAdd",
        name: "PartyAdd",
        component: PartyAdd,
        meta:{
          showNav: true
        }
      },
      {
        path: "partyChange",
        name: "PartyChange",
        component: PartyChange,
        meta:{
          showNav: true
        }
      },
      {
        path: "register",
        name: "Register",
        component: Register,
        meta:{
          showNav: true
        }
      },
      {
        // 当 /user/:id/posts 匹配成功
        // UserPosts 会被渲染在 User 的 <router-view> 中
        path: "user",
        component: User,
        meta:{
          showNav: true
        }
      },
      {
        // 当 /user/:id/posts 匹配成功
        // UserPosts 会被渲染在 User 的 <router-view> 中
        path: "advise",
        component: Advise,
        meta:{
          showNav: true
        }
      },
      {
        // 当 /user/:id/posts 匹配成功
        // UserPosts 会被渲染在 User 的 <router-view> 中
        path: "announcement",
        name:'Announcement',
        component: Announcement,
        meta:{
          showNav: true
        }
      },
      {
        // 当 /user/:id/posts 匹配成功
        // UserPosts 会被渲染在 User 的 <router-view> 中
        path: "imageup",
        component: Imageup,
        meta:{
          showNav: true
        }
      },
      {
        // 当 /user/:id/posts 匹配成功
        // UserPosts 会被渲染在 User 的 <router-view> 中
        path: "news",
        component: News,
        meta:{
          showNav: true
        }
      },
      {
        // 当 /user/:id/posts 匹配成功
        // UserPosts 会被渲染在 User 的 <router-view> 中
        path: "allparty",
        component: Party,
        meta:{
          showNav: true
        }
      },
      {
        // 当 /user/:id/posts 匹配成功
        // UserPosts 会被渲染在 User 的 <router-view> 中
        path: "MsgType",
        component: MsgType,
        meta:{
          showNav: true
        }
      },
      
      {
        // 当 /user/:id/posts 匹配成功
        // UserPosts 会被渲染在 User 的 <router-view> 中
        path: "videoup",
        component: Videoup,
        meta:{
          showNav: true
        }
      },
      {
        // 当 /user/:id/posts 匹配成功
        // UserPosts 会被渲染在 User 的 <router-view> 中
        path: "switch",
        component: Switch,
        meta:{
          showNav: true
        }
      },
      {
        // 当 /user/:id/posts 匹配成功
        // UserPosts 会被渲染在 User 的 <router-view> 中
        path: "notice",
        component: Notice,
        meta:{
          showNav: true
        }
      },
      {
        path: "notice_add",
        name: "Notice_add",
        component: Notice_add,
        meta:{
          showNav: true
        }
      },
      {
        path: "notice_update",
        name: "Notice_update",
        component: Notice_update,
        meta:{
          showNav: true
        }
      },
      {
        path: "addimage",
        name: "Addimage",
        component: Addimage,
        meta:{
          showNav: true
        }
      },
      {
        path: "imageChange",
        name: "ImageChange",
        component: ImageChange,
        meta:{
          showNav: true
        }
      },
      {
        path: "newsAdd",
        name: "NewsAdd",
        component: NewsAdd,
        meta:{
          showNav: true
        }
      },
      {
        path: "newsChange",
        name: "NewsChange",
        component: NewsChange,
        meta:{
          showNav: true
        }
      },
      {
        path: "strollAdd",
        name: "StrollAdd",
        component:StrollAdd,
        meta:{
          showNav: true
        }
      },
      {
        path: "strollChange",
        name: "StrollChange",
        component:StrollChange,
        meta:{
          showNav: true
        }
      },
    ],
  },
  
]

const router = new VueRouter({
  mode: 'hash',
//   mode:'history',
  // base: process.env.BASE_URL,
  routes
})
// router.beforeEach((to, from, next) => {
//   if (to.path == "/login") {
//     next();
//   } else if (!localStorage.getItem("islogin")) {
//     next("/login"); //转入login登录页面，登录成功后会将token存入localStorage
//   } else {
//     next();
//   }
// });
export default router
