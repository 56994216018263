<template>
  <div v-if="list">
    <el-card class="box-card">
        <el-form :model="list" label-width="100px">
            <el-form-item label="图片名称" prop="imageTitle">
                <el-input v-model="list.imageTitle" style="width:400px"></el-input>
            </el-form-item>
            <el-form-item label="排序" prop="imageSerial">
                <el-input type="unmber" min="1" v-model="list.imageSerial" style="width:400px"></el-input>
            </el-form-item>
            <el-form-item label="图片" prop="xlsxFile">
                <div class="upload_box">
                    <input class="upload_input" type="file" @change="getFile"/>
                    <el-image class="upload_img" :src="list.imagePath" fit="contain" v-if="list.imagePath">
                        <div slot="error" class="image-slot">
                            <i class="el-icon-picture-outline"></i>
                        </div>
                    </el-image>
                    <i class="el-icon-plus upload_icon" v-if="!list.imagePath"></i>
                </div>
            </el-form-item>
            <el-form-item label="详情" prop="imageDetail" v-if="list.imageType == 1">
                <quill @quill="quillVal" :Pquillval="list.imageDetail"></quill>
            </el-form-item>
            <el-form-item>
                <div class="d_flex jc_end" style="margin-top:20px">
                    <el-button type="primary" @click="importRow">确认修改</el-button>
                </div>
            </el-form-item>
        </el-form>
    </el-card>
  </div>
</template>
<script>
import {imgToFile} from '@/utils/index'
import Quill from './Quill.vue';
export default {
  data() {
    return {
      list: null,
      xlsxFile:null
    };
  },
  components:{
    Quill
  },
  mounted() {
    // this.list = this.$route.query;
    // imgToFile(this.list.imagePath,file=>{
    //     this.xlsxFile = file
    // })
    this.getDetail(this.$route.query.id)
    // this.list.imageType = this.list.imageType == 0 ? '大图' : '小图'
    // console.log(this.list);
  },
  methods: {
    getDetail(id){
        this.axios.get('image/queryImageById',{params:{id}}).then(res=>{
            res.data.data.imagePath = this.$store.state.imgUrl + "/images/" + res.data.data.imagePath
            imgToFile(res.data.data.imagePath,file=>{
                this.xlsxFile = file
            })
            this.list = res.data.data
            console.log('详情',res.data.data)
        })
    },
    quillVal(data){
      this.list.imageDetail = data.html
    },
    getFile(e) {
      //获取file内容
      let files = e.target.files[0];
      // console.log(this.$refs.fileId.files[0])
      this.xlsxFile = files;
      this.list.imagePath = this.getObjectURL(files);
    },
    //建立一個可存取到該file的url
    getObjectURL(file){
        let url = null
        // 下面函数执行的效果是一样的，只是需要针对不同的浏览器执行不同的 js 函数而已
        if (window.createObjectURL != undefined) {
            // basic
            url = window.createObjectURL(file)
        } else if (window.URL != undefined) {
            // mozilla(firefox)
            url = window.URL.createObjectURL(file)
        } else if (window.webkitURL != undefined) {
            // webkit or chrome
            url = window.webkitURL.createObjectURL(file)
        }
        return url
    },
    //上传文件
    importRow() {
      
      let that = this;
      // if (that.xlsxFile == undefined) {
      //   that.xlsxFile = null
      // }
      console.log(this.xlsxFile)
      // vue 中使用 window.FormData(),否则会报 'FormData isn't definded'
      //创建一个FormData对象，然后通过append() 方法向对象中添加键值对
      // this.$set(that.list,that.list.newsTitle,that.list.newsTitle)
      let formData = new FormData();
      formData.append("file", that.xlsxFile ? that.xlsxFile: null);
      formData.append("imageType",  this.list.imageType);
      formData.append("imageTitle",  this.list.imageTitle);
      formData.append("imageId",  this.list.imageId);
      formData.append("imageSerial",  this.list.imageSerial);
        formData.append("imageDetail",  this.list.imageDetail);
      // console.log(formData.get("file"));
      
      that.axios
        .post("/image/updateImage", formData, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        })
        .then((res) => {
          // console.log(res);
          if(res.data.code == 200){
            this.$message({
                message: '修改成功',
                type: 'success'
            })
            history.go(-1)
            // this.$router.push('/pageAdmin/imageup')
          }
        })
        .catch((res) => {});
    },
  },
};
</script>
<style scoped>
.upload_box{
    position:relative;
    height:140px;
}
.upload_input{
    position:absolute;
    width:140px;
    height:140px;
    opacity:0;
    z-index:1;
}
.upload_img{
    width: 140px;
    height: 140px;
    position:absolute;
    left:0;
    top:0;
    border:1px solid #ddd;
    border-radius:8px;
    overflow:hidden;
}
.upload_icon{
    position:absolute;
    left:0;
    top:0;
    width:140px;
    height:140px;
    border:1px solid #ddd;
    border-radius:8px;
    font-size:32px;
    line-height:140px;
    text-align:center;
}
</style>
