<template>
    <div class="ticket_page">
        <el-card class="box-card">
            <p class="title" >
                {{gpInfo.typeTitle}}
            </p>
            <div class="boxa">
                <img :src="gpInfo.typePhoto" alt="" />
            </div>
        </el-card>
    </div>
</template>
<script>
export default {
    data(){
        return {
            gpInfo:{

            }
        }
    },
    mounted(){
        this.init()
    },
    methods:{
        init(){
            this.axios.get("/init/").then((res) => {
                res.data.data.plateType.typePhoto = this.$store.state.imgUrl + "/images/" + res.data.data.plateType.typePhoto
                this.gpInfo = res.data.data.plateType
            }).catch((err) => {
                console.error(err);
            });
        },
    }
}
</script>

<style scoped>
@import "../assets/css/index_response.css";
.ticket_page{
    padding: 75px 20px 0;
    min-height: 100%;
    box-sizing: border-box;
    text-align: center;
}
.title{
font-size: 27px;
color: black;
font-weight: 600;
}
</style>
