<template>
  <div>
    <el-card class="box-card">
        <el-form :model="params" label-width="200px" :rules="rules" ref="ruleForm">
            <el-form-item :label="'要添加的'+detailObj.typeTitle+'标题：'" prop="partyTitle">
                <el-input v-model="params.partyTitle" style="width:400px"></el-input>
            </el-form-item>
            <el-form-item label="时间" prop="partyAddTime">
                <el-date-picker v-model="params.partyAddTime" type="datetime" placeholder="选择日期" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
            </el-form-item>
            <el-form-item :label="'要添加的'+detailObj.typeTitle+'概括：'" prop="partySynopsis">
                <el-input v-model="params.partySynopsis" type="textarea" :rows="3" style="width:400px"></el-input>
            </el-form-item>
            <el-form-item :label="'要添加的'+detailObj.typeTitle+'内容：'" prop="partyContext">
                <quill @quill = "quillVal" :Pquillval="params.partyContext"></quill>
            </el-form-item>
            <el-form-item :label="detailObj.typeTitle+'信息的图片：'" prop="xlsxFile">
                <input type="file" ref="fileId" @change="getFile" />
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="importRow" >确认保存</el-button>
            </el-form-item>
        </el-form>
    </el-card>
    <!-- <h1>（必填）要添加的{{detailObj.typeTitle}}标题：</h1>
    <el-input type="textarea" :rows="5" v-model="partyTitle"> </el-input>
    <h1>（必填）要添加的{{detailObj.typeTitle}}概括：</h1>
    <el-input type="textarea" :rows="5" v-model="partySynopsis"> </el-input>
    <h1>（必填）要添加的{{detailObj.typeTitle}}内容：</h1>
    <quill @quill = "quillVal" :Pquillval="partyContext"></quill>
    
    <h1>（必填）{{detailObj.typeTitle}}信息的图片：</h1>

    <div class="fileItem">
      <input type="file" ref="fileId" @change="getFile" />
    </div>
    <el-button type="primary" @click="importRow" >确认保存</el-button> -->
  </div>
</template>
<script>
import Quill from './Quill.vue';
export default {
    props:['typeList'],
  data() {
    return {
        params:{
            partyTitle: "", 
            partyContext: "",
            partyPath: "",
            partySynopsis:"",
            partyAddTime:null,
            xlsxFile:null
        },
        rules:{
            partyTitle:{ required: true, message: '标题不能为空', trigger: 'blur' },
            partyAddTime:{required: true, message: '时间不能为空', trigger: 'blur'},
            xlsxFile:{ required: true, message: '图片不能为空', trigger: 'blur' },
            partySynopsis:{ required: true, message: '概括不能为空', trigger: 'blur' },
            partyContext:{ required: true, message: '内容不能为空', trigger: 'blur' }
        }

    };
  },
  computed:{
    detailObj(){
        let obj = this.typeList.find(val=>{return val.typeId == 3})
        if(obj !== undefined){
            return obj
        }else{
            return {typeTitle:'-'}
        }
    },
  },
  mounted() {

  },
  methods: {
    // 副文本传参
    quillVal(data){
      // console.log(data)
      this.params.partyContext = data.html
    },
    getFile() {
      //获取file内容
      let files = this.$refs.fileId.files[0];
      // console.log(this.$refs.fileId.files[0])
      this.params.xlsxFile = files;
    },
    //上传文件
    importRow() {
      // console.log(this.partyContext)
      let that = this;
      this.$refs.ruleForm.validate((valid) => {
            if (valid) {
                let formData = new FormData();
                formData.append("file", that.params.xlsxFile);
                formData.append("partyContext",  this.params.partyContext);
                formData.append("partyPath",  this.params.partyPath);
                formData.append("partyTitle",  this.params.partyTitle);
                formData.append("partySynopsis",  this.params.partySynopsis);
                formData.append("partyAddTime",  this.params.partyAddTime);
                formData.append("partyTop",  0);
                that.axios
                    .post("/party/addParty", formData, {
                    headers: { "Content-Type": "multipart/form-data" },
                    })
                    .then((res) => {
                    // console.log(res);
                    if(res.data.code == 200){
                        alert('添加成功')
                        this.$router.push('/pageAdmin/party')
                    }
                    })
                    .catch((res) => {});
            }
        });
      
    },
  },
  components:{
    Quill
  }
};
</script>
<style scoped></style>
