<template>
  <div>
    <el-card class="box-card">
        <el-button type="primary" @click="addimage">新增</el-button>
        <el-table  :header-cell-style="{textAlign:'center',backgroundColor:'#f5f7fa'}" stripe tooltip-effect="light" :data="tableData" style="width: 100%;margin-top:25px">
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column prop="imageId" label="图片ID" width="100">
        </el-table-column>

        <el-table-column prop="imageTitle" label="图片标题" width="120">
        </el-table-column>
        <el-table-column prop="imageSerial" label="图片轮播排序" width="110">
        </el-table-column>
        <el-table-column label="图片">
            <template slot-scope="scope">
            <!-- <el-popover placement="top-start" title="" trigger="hover">
                <img :src="scope.row.imagePath" alt="" style="width: 150px;">
                <img slot="reference" :src="scope.row.imagePath" style="width: 30px;height: 30px">
                </el-popover> -->
            <el-image
                style="width: 30px; height: 30px"
                :src="scope.row.imagePath"
                :preview-src-list="[scope.row.imagePath]"
            >
                <div slot="error" class="image-slot">
                <i class="el-icon-picture-outline"></i>
                </div>
            </el-image>
            </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="100">
            <template slot-scope="scope">
            <el-button @click="handleClick(scope.row)" type="text" size="small"
                >编辑</el-button
            >
            <el-button @click="removeClick(scope.row)" type="text" size="small"
                >删除</el-button
            >
            </template>
        </el-table-column>
        </el-table>
    </el-card>
    <!-- <div style="margin-top: 20px">
      <el-button @click="toggleSelection([tableData[1], tableData[2]])"
        >切换第二、第三行的选中状态</el-button
      >
      <el-button @click="toggleSelection()">取消选择</el-button>
    </div> -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      tableData: [],
      multipleSelection: [],
      changeContext: "小轮播",
    };
  },
  mounted() {
    this.getImagelist("Big");
  },
  methods: {
    changeType() {
      if (this.changeContext == "大轮播") {
        this.changeContext = "小轮播";
        this.getImagelist("Big");
      } else {
        this.changeContext = "大轮播";
        this.getImagelist("Small");
      }
    },
    // toggleSelection(rows) {
    //   if (rows) {
    //     rows.forEach((row) => {
    //       this.$refs.multipleTable.toggleRowSelection(row);
    //     });
    //   } else {
    //     this.$refs.multipleTable.clearSelection();
    //   }
    // },
    // handleSelectionChange(val) {
    //   this.multipleSelection = val;
    // },
    getImagelist(type) {
      this.axios
        .get("/image/queryImageListBy" + type + "?pageNum=1&pageSize=5000")
        .then((res) => {
          // console.log(res);
          const list = res.data.data.list;
          list.forEach((element) => {
            element.imagePath =
              this.$store.state.imgUrl + "/images/" + element.imagePath;
          });
          this.tableData = list;
        });
    },
    addimage() {
      this.$router.push({path:'/pageAdmin/addimage',query:{type:0}});
    },
    handleClick(row) {
      // console.log(row);
      this.$router.push({ name: "ImageChange", query: { id:row.imageId } });
    },
    removeClick(row) {
      // console.log(row.imageId);
      let result = confirm(
        `是否删除ID为${row.imageId}的数据，如果删除，无法恢复数据！`
      );
      if (result) {
        this.axios
          .delete(`/image/deleteImageById?id=${row.imageId}`)
          .then((res) => {
            if (res.data.code == 200) {
              alert(`成功删除ID为${row.imageId}的数据`);
              this.$router.go(0);
            }
          });
      }
    },
  },
};
</script>
